/*=========================================================================================
	File Name: horizontal-menu.scss
	Description: A classic horizontal menu for easy navingation & support all devices.
	It support light & dark version, filpped layout, right side icons, borders menu for
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Stack - Responsive Admin Theme
	Version: 3.2
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.horizontal-menu .is-sticky .header-navbar {
  box-shadow: 0px 4px 12px 0 rgba(25, 42, 70, 0.13);
}

.horizontal-menu.navbar-sticky {
  top: 0;
}

.horizontal-menu.navbar-sticky .header-navbar {
  position: fixed;
  width: 100%;
}

.horizontal-menu.navbar-sticky .content > .content-wrapper {
  margin-top: 3.75rem !important;
}

.horizontal-menu.navbar-static .header-navbar.navbar-sticky {
  position: sticky;
  top: 0;
}

.horizontal-menu.navbar-static .content > .content-wrapper {
  margin-top: 0;
}

.horizontal-menu.navbar-static.navbar-scrolled .header-navbar.navbar-sticky {
  position: fixed;
}

.horizontal-menu .content {
  margin-left: 0;
}

.horizontal-menu .header-navbar {
  background-color: #F2F4F4;
  border-bottom: 1px solid #DFE3E7;
  z-index: 999 !important;
  left: 0 !important;
}

.horizontal-menu .header-navbar .navbar-container {
  padding-left: 1.5rem !important;
  padding-right: 1.3rem !important;
}

.horizontal-menu .header-navbar .navbar-container .bookmark-wrapper .menu-toggle {
  top: 2px;
}

.horizontal-menu .header-navbar .navbar-container a.nav-link-expand {
  padding: 1.2rem 0.75rem 1.15rem 0.75rem !important;
}

.horizontal-menu .header-navbar .navbar-wrapper {
  margin-left: 0 !important;
}

.horizontal-menu .header-navbar .navbar-wrapper .nav-search .search-input input {
  padding: 1.7rem 3.6rem !important;
}

.horizontal-menu .header-navbar.navbar-horizontal {
  top: 65px;
  position: fixed;
  width: 100%;
  z-index: 99 !important;
}

.horizontal-menu .header-navbar.navbar-horizontal.navbar-static-top {
  position: absolute !important;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  position: absolute;
  left: 50%;
  margin-left: -65px;
  padding: 0;
  z-index: 1000;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 0;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-logo {
  height: 30px;
  width: 30px;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-logo .logo {
  height: 30px;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-text {
  color: #FFFFFF;
  padding-left: 1rem;
  letter-spacing: 0.01rem;
  font-size: 1.57rem;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu {
  position: relative;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu.openLeft .dropdown-menu {
  left: auto !important;
  right: 100%;
}

.horizontal-menu .navbar-expand-lg .navbar-nav .dropdown-menu {
  z-index: 100;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown-menu li a {
  transition: all .35s ease !important;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown-menu li a i {
  margin-right: 0.2rem;
  font-size: 1rem;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown-menu li:hover > a {
  padding-left: 25px;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.horizontal-menu .navbar-expand-sm .navbar-nav li i {
  width: 1.6rem !important;
  margin-right: 0.4rem;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li {
  padding: 10px 0;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li.active > a, .horizontal-menu .navbar-expand-sm .navbar-nav > li.sidebar-group-active > a {
  background-color: #e9ecec;
  border-radius: 4px;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li .nav-link {
  padding-right: .85rem;
  padding-left: .85rem;
  display: flex;
  align-items: center;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li .nav-link:after {
  left: 0.5rem;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li li:not(.sidebar-group-active).active a {
  background: rgba(90, 141, 238, 0.2) !important;
  color: #5A8DEE !important;
  border-left: 4px solid #5A8DEE;
  padding-left: 1rem !important;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li li:not(.sidebar-group-active).active a i {
  color: #5A8DEE;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li li.sidebar-group-active {
  background-color: #f2f4f4;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li li.sidebar-group-active > a > i {
  color: #5A8DEE !important;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li li.sidebar-group-active .dropdown-menu li:not(.sidebar-group-active).active a {
  background: rgba(90, 141, 238, 0.2);
  color: #5A8DEE;
  border-left: 4px solid #5A8DEE;
  padding-left: 1.4rem;
}

.horizontal-menu .navbar-expand-sm .navbar-nav > li li.sidebar-group-active .dropdown-menu li:not(.sidebar-group-active).active a i {
  color: #5A8DEE;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown.show > .dropdown-menu {
  animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-menu {
  min-width: 270px;
  border: 1px solid #DFE3E7;
  padding: 0.7rem 0;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-menu:before {
  border: none;
  background-color: transparent;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-menu .dropdown-submenu.show > .dropdown-menu {
  display: block;
  top: 0 !important;
  left: 100%;
  margin-top: 4px;
  margin-left: 1px;
  animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-menu .dropdown-submenu .dropdown-toggle:after {
  content: "\ea4a" !important;
  font-family: 'boxicons';
  font-size: 1rem;
  color: #8494a7;
  display: inline-block;
  right: 18px;
  top: 11px;
  left: auto;
  position: absolute;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-menu .dropdown-submenu ul li a i {
  margin-right: 0;
  font-size: 1rem;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-item {
  text-transform: capitalize;
  font-size: 1rem;
  padding: 0.7rem 1.3rem;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-item:active, .horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-item:focus, .horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-item:hover {
  background-color: transparent;
  color: inherit;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-item:active i, .horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-item:focus i, .horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-item:hover i {
  color: inherit;
}

.horizontal-menu .navbar-expand-sm .navbar-nav .dropdown .dropdown-toggle:after {
  content: "\ea4f";
  font-family: 'boxicons';
  font-size: 1.2rem;
  color: #8494a7;
  display: inline-block;
  right: 15px;
}

.horizontal-menu.menu-collapsed #main-menu-navigation > li .nav-link {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.horizontal-menu.menu-collapsed #main-menu-navigation > li .nav-link span, .horizontal-menu.menu-collapsed #main-menu-navigation > li .nav-link:after {
  display: none;
}

.horizontal-menu.menu-collapsed #main-menu-navigation > li .nav-link i {
  margin-right: 0;
}

.horizontal-menu.email-application .content .content-wrapper, .horizontal-menu.chat-application .content .content-wrapper, .horizontal-menu.todo-application .content .content-wrapper, .horizontal-menu.file-manager-application .content .content-wrapper {
  height: calc(100vh - 15rem) !important;
}

.horizontal-menu.navbar-sticky .content .content-area-wrapper {
  padding-top: 0;
  margin-top: 10rem !important;
}

.horizontal-menu.email-application .content-area-wrapper .content-right .email-app-list-wrapper .email-app-list .email-user-list {
  height: calc(100vh - 19.67rem);
}

.horizontal-menu.email-application .content-area-wrapper .email-app-sidebar .email-app-menu .form-group-compose {
  margin-bottom: 0 !important;
}

.horizontal-menu.email-application .content-area-wrapper .sidebar .email-app-sidebar {
  height: calc(100vh - 15rem);
}

.horizontal-menu.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu .sidebar-menu-list {
  height: calc(100% - 6.4rem);
}

.horizontal-menu.email-application .content-area-wrapper .sidebar .compose-new-mail-sidebar {
  height: calc(100vh - 15rem);
}

.horizontal-menu.email-application .content-area-wrapper .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header .card-header .information .dropdown-menu {
  transform: translate3d(11px, 1px, 0px) !important;
}

.horizontal-menu.chat-application .chat-user-profile,
.horizontal-menu.chat-application .chat-profile,
.horizontal-menu.chat-application .chat-sidebar {
  height: calc(100vh - 15rem);
}

.horizontal-menu.chat-application .chat-user-profile .chat-user-profile-scroll, .horizontal-menu.chat-application .chat-user-profile .chat-profile-content,
.horizontal-menu.chat-application .chat-profile .chat-user-profile-scroll,
.horizontal-menu.chat-application .chat-profile .chat-profile-content,
.horizontal-menu.chat-application .chat-sidebar .chat-user-profile-scroll,
.horizontal-menu.chat-application .chat-sidebar .chat-profile-content {
  height: calc(100vh - 28.5rem) !important;
}

.horizontal-menu.chat-application .chat-profile {
  bottom: 5rem;
}

.horizontal-menu.chat-application .chat-window-wrapper .chat-container {
  height: calc(100vh - 23.6rem);
}

.horizontal-menu.chat-application .chat-window-wrapper .chat-start {
  height: calc(100vh - 15rem);
}

.horizontal-menu.todo-application .content-area-wrapper .todo-new-task-sidebar {
  height: calc(100vh - 15rem);
  bottom: 5rem;
}

.horizontal-menu.todo-application .content-area-wrapper .sidebar .todo-sidebar {
  height: calc(100vh - 15rem);
}

.horizontal-menu.todo-application .content-area-wrapper .content-right .todo-app-list-wrapper .todo-app-list .todo-task-list {
  height: calc(100vh - 18.7rem);
}

.horizontal-menu.file-manager-application .content-area-wrapper .sidebar .app-file-sidebar {
  height: calc(100vh - 14rem);
}

.horizontal-menu.file-manager-application .content-area-wrapper .content-right .app-file-area .app-file-content {
  height: calc(100vh - 17.85rem);
}

.horizontal-menu.file-manager-application .content-area-wrapper .sidebar .app-file-sidebar-info {
  height: calc(100vh - 14rem);
  bottom: 4rem;
}

@keyframes fadein {
  from {
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    transform: translate3d(0, -6px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@media (max-width: 1199.98px) {
  .horizontal-layout .header-navbar .navbar-container {
    padding-left: 1.5rem !important;
    padding-right: 1rem !important;
  }
  .horizontal-layout .header-navbar .navbar-container .bookmark-wrapper .menu-toggle {
    top: 2px;
  }
  .horizontal-layout .header-navbar .navbar-container button.nav-link-expand {
    padding: 1.2rem 0.75rem 1.15rem 0.75rem !important;
  }
}

@media (max-width: 991.98px) {
  .horizontal-layout .header-navbar .navbar-container {
    padding-left: 1.8rem !important;
  }
}

@media (max-width: 576px) {
  .horizontal-layout .header-navbar .navbar-container {
    padding-left: 1rem !important;
    padding-right: 0.2rem !important;
    width: 100%;
  }

  .navbar-nav {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

/*=========================================================================================
    File Name: vertical-overlay-menu.scss
    Description: A overlay style vertical menu with show and hide support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Frest HTML Admin Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.vertical-overlay-menu .content {
  margin-left: 0;
}

.vertical-overlay-menu .navbar .navbar-header {
  float: left;
  width: 260px;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header {
  padding: 0;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 0;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand .brand-logo {
  height: 27px;
  width: 35px;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand .brand-logo .logo {
  height: 27px;
  display: flex;
  position: relative;
  left: 6px;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand .brand-text {
  color: #FFFFFF;
  padding-left: 1rem;
  letter-spacing: 0.01rem;
  font-size: 1.57rem;
}

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: width .25s,opacity .25s,transform .25s;
  width: 260px;
  left: -260px;
}

.vertical-overlay-menu .main-menu .navigation > li > a > span.badge {
  position: absolute;
  right: 20px;
}

.vertical-overlay-menu .main-menu .navigation > li > a > i {
  margin-right: 14px;
  float: left;
  width: 1.6rem !important;
}

.vertical-overlay-menu .main-menu .navigation > li ul li > a {
  transition: all .35s ease !important;
}

.vertical-overlay-menu .main-menu .navigation > li ul li > a > i {
  margin-right: 1.25rem;
}

.vertical-overlay-menu .main-menu .navigation > li ul li:hover a {
  padding-left: 35px;
}

.vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after {
  content: "\ea4f";
  font-family: "boxicons";
  font-size: 1.2rem;
  color: #8494a7;
  display: inline-block;
  position: absolute;
  right: 7px;
  transform: rotate(0deg);
  transition: -webkit-transform 0.4s ease-in-out;
}

.vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after {
  transform: rotate(180deg);
}

.vertical-overlay-menu.menu-open .main-menu {
  opacity: 1;
  transform: translate3d(260px, 0, 0);
  transition: width .25s,opacity .25s,transform .25s;
  top: 0;
}

@media (max-width: 1199.98px) {
  .horizontal-layout.vertical-overlay-menu .app-content .content-wrapper {
    margin-top: 3.5rem;
  }
}
