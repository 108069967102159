/* Colors CSS */
/* --------- */
/*------------------------------------------------------------------
[Table of contents for each color options]

- Text Color
- Background Color
- Alert
- Border
- Badges
- Buttons
- Dropdowns
- Bullet
- Pagination
- Progress bars
- Chips
- Divider
- Timeline
- Checkboxes & Radio
- Custom Switches
- Touchspin
- Scrumboard
- Select2
-------------------------------------------------------------------*/
/* white Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.white {
  color: #FFFFFF !important;
}

/* text with light white */
.text-light-white {
  color: rgba(255, 255, 255, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-white {
  background-color: #FFFFFF !important;
}

.bg-white .card-header,
.bg-white .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-white {
  background: rgba(255, 255, 255, 0.2) !important;
}

.bg-rgba-white.alert {
  color: #FFFFFF;
}

.bg-rgba-white.alert.alert-dismissible .close {
  color: #FFFFFF;
}

/* Alert white */
/* ---------------- */
.alert.alert-white {
  background: #FFFFFF !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(255, 255, 255, 0.4);
  border: none;
}

/* Border white */
/* ---------------- */
.border-white {
  border: 1px solid #FFFFFF !important;
}

.border-white.alert {
  color: #FFFFFF;
}

.border-white.alert.alert-dismissible .close {
  color: #FFFFFF;
}

.border-top-white {
  border-top: 1px solid #FFFFFF;
}

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF;
}

.border-left-white {
  border-left: 1px solid #FFFFFF;
}

.border-right-white {
  border-right: 1px solid #FFFFFF;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-white .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #FFFFFF !important;
}

/* Badges */
/* ------ */
.badge.badge-white {
  background-color: #FFFFFF;
}

.badge.badge-light-white {
  color: #FFFFFF !important;
}

.badge.bg-white.badge-glow, .badge.border-white.badge-glow, .badge.badge-white.badge-glow {
  box-shadow: 0px 0px 10px #FFFFFF;
}

.badge-circle-white {
  background-color: #FFFFFF;
}

.badge-circle-light-white {
  color: #FFFFFF;
}

.overlay-white {
  background: #FFFFFF;
  background: rgba(255, 255, 255, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-white {
  background-color: #FFFFFF !important;
  color: #fff;
}

.btn-white:hover, .btn-white.hover {
  color: #fff;
}

.btn-white:hover.glow, .btn-white.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(255, 255, 255, 0.6) !important;
}

.btn-white:focus, .btn-white:active, .btn-white.active {
  color: #fff !important;
}

.btn-white.glow {
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.5) !important;
}

.btn-white:disabled, .btn-white.disabled {
  color: #fff !important;
}

.btn-light-white {
  color: #FFFFFF !important;
}

.btn-light-white:hover, .btn-light-white.hover {
  color: #fff !important;
}

.btn-light-white:active, .btn-light-white.active {
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-white {
  border: 1px solid #FFFFFF;
  background-color: transparent;
  color: #FFFFFF !important;
}

.btn-outline-white:hover, .btn-outline-white.hover {
  color: #fff !important;
}

.btn-outline-white:active, .btn-outline-white.active {
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-white ~ .dropdown-menu .dropdown-item.active, .btn-outline-white ~ .dropdown-menu .dropdown-item.active {
  background-color: #FFFFFF;
  color: #fff;
}

.btn-white ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-white ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:hover i {
  color: #FFFFFF;
}

.dropdown.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-white ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-white ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet white */
.bullet.bullet-white {
  background-color: #FFFFFF;
}

/* For Pagination */
/* -------------- */
.pagination-white .page-item.active .page-link, .pagination-white .page-item.active .page-link:hover {
  background: #FFFFFF !important;
  color: #fff;
}

.pagination-white .page-item.previous .page-link, .pagination-white .page-item.next .page-link {
  color: #FFFFFF;
}

.pagination-white .page-item.previous .page-link:hover, .pagination-white .page-item.next .page-link:hover {
  background: #FFFFFF;
}

/* Progress Bars white */
/* ---------------------- */
.progress-bar-white .progress-bar {
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(255, 255, 255, 0.6);
}

/* Chips white */
/* ---------------- */
.chip-white {
  background-color: #FFFFFF !important;
}

.chip-white .chip-body {
  color: #fff !important;
}

/* Divider white */
/* ---------------*/
.divider.divider-white .divider-text:before, .divider.divider-white .divider-text:after {
  border-color: #FFFFFF !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-white:before {
  background: #FFFFFF !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-white + .custom-control-label:before, input[type="radio"].bg-white + .custom-control-label:before {
  background-color: #FFFFFF !important;
}

.checkbox.checkbox-white input:checked ~ label::before, .checkbox.radio-white input:checked ~ label::before, .radio.checkbox-white input:checked ~ label::before, .radio.radio-white input:checked ~ label::before {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.checkbox.checkbox-white input:checked ~ label::after, .checkbox.radio-white input:checked ~ label::after, .radio.checkbox-white input:checked ~ label::after, .radio.radio-white input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-white.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-white.radio-glow input:checked ~ label::before, .checkbox.radio-white.checkbox-glow input:checked ~ label::before, .checkbox.radio-white.radio-glow input:checked ~ label::before, .radio.checkbox-white.checkbox-glow input:checked ~ label::before, .radio.checkbox-white.radio-glow input:checked ~ label::before, .radio.radio-white.checkbox-glow input:checked ~ label::before, .radio.radio-white.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.7);
}

.checkbox.checkbox-white.checkbox-icon label i, .checkbox.radio-white.checkbox-icon label i, .radio.checkbox-white.checkbox-icon label i, .radio.radio-white.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-white label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-white input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-white .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFFFFF !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-white.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-white, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-white {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=white]:before {
  background-color: #FFFFFF;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #FFFFFF;
}

/* Select2 white */
/* ---------------*/
select.select-light-white ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-white ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  color: #FFFFFF !important;
}

/* black Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.black {
  color: #000000 !important;
}

/* text with light black */
.text-light-black {
  color: rgba(0, 0, 0, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-black {
  background-color: #000000 !important;
}

.bg-black .card-header,
.bg-black .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-black {
  background: rgba(0, 0, 0, 0.2) !important;
}

.bg-rgba-black.alert {
  color: #000000;
}

.bg-rgba-black.alert.alert-dismissible .close {
  color: #000000;
}

/* Alert black */
/* ---------------- */
.alert.alert-black {
  background: #000000 !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.4);
  border: none;
}

/* Border black */
/* ---------------- */
.border-black {
  border: 1px solid #000000 !important;
}

.border-black.alert {
  color: #000000;
}

.border-black.alert.alert-dismissible .close {
  color: #000000;
}

.border-top-black {
  border-top: 1px solid #000000;
}

.border-bottom-black {
  border-bottom: 1px solid #000000;
}

.border-left-black {
  border-left: 1px solid #000000;
}

.border-right-black {
  border-right: 1px solid #000000;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-black .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #000000 !important;
}

/* Badges */
/* ------ */
.badge.badge-black {
  background-color: #000000;
}

.badge.badge-light-black {
  color: #000000 !important;
}

.badge.bg-black.badge-glow, .badge.border-black.badge-glow, .badge.badge-black.badge-glow {
  box-shadow: 0px 0px 10px #000000;
}

.badge-circle-black {
  background-color: #000000;
}

.badge-circle-light-black {
  color: #000000;
}

.overlay-black {
  background: #000000;
  background: rgba(0, 0, 0, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-black {
  background-color: #000000 !important;
  color: #fff;
}

.btn-black:hover, .btn-black.hover {
  color: #fff;
}

.btn-black:hover.glow, .btn-black.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.6) !important;
}

.btn-black:focus, .btn-black:active, .btn-black.active {
  color: #fff !important;
}

.btn-black.glow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
}

.btn-black:disabled, .btn-black.disabled {
  color: #fff !important;
}

.btn-light-black {
  color: #000000 !important;
}

.btn-light-black:hover, .btn-light-black.hover {
  color: #fff !important;
}

.btn-light-black:active, .btn-light-black.active {
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-black {
  border: 1px solid #000000;
  background-color: transparent;
  color: #000000 !important;
}

.btn-outline-black:hover, .btn-outline-black.hover {
  color: #fff !important;
}

.btn-outline-black:active, .btn-outline-black.active {
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-black ~ .dropdown-menu .dropdown-item.active, .btn-outline-black ~ .dropdown-menu .dropdown-item.active {
  background-color: #000000;
  color: #fff;
}

.btn-black ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-black ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:hover i {
  color: #000000;
}

.dropdown.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-black ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-black ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet black */
.bullet.bullet-black {
  background-color: #000000;
}

/* For Pagination */
/* -------------- */
.pagination-black .page-item.active .page-link, .pagination-black .page-item.active .page-link:hover {
  background: #000000 !important;
  color: #fff;
}

.pagination-black .page-item.previous .page-link, .pagination-black .page-item.next .page-link {
  color: #000000;
}

.pagination-black .page-item.previous .page-link:hover, .pagination-black .page-item.next .page-link:hover {
  background: #000000;
}

/* Progress Bars black */
/* ---------------------- */
.progress-bar-black .progress-bar {
  background-color: #000000;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
}

/* Chips black */
/* ---------------- */
.chip-black {
  background-color: #000000 !important;
}

.chip-black .chip-body {
  color: #fff !important;
}

/* Divider black */
/* ---------------*/
.divider.divider-black .divider-text:before, .divider.divider-black .divider-text:after {
  border-color: #000000 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-black:before {
  background: #000000 !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-black + .custom-control-label:before, input[type="radio"].bg-black + .custom-control-label:before {
  background-color: #000000 !important;
}

.checkbox.checkbox-black input:checked ~ label::before, .checkbox.radio-black input:checked ~ label::before, .radio.checkbox-black input:checked ~ label::before, .radio.radio-black input:checked ~ label::before {
  background-color: #000000;
  border-color: #000000;
}

.checkbox.checkbox-black input:checked ~ label::after, .checkbox.radio-black input:checked ~ label::after, .radio.checkbox-black input:checked ~ label::after, .radio.radio-black input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-black.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-black.radio-glow input:checked ~ label::before, .checkbox.radio-black.checkbox-glow input:checked ~ label::before, .checkbox.radio-black.radio-glow input:checked ~ label::before, .radio.checkbox-black.checkbox-glow input:checked ~ label::before, .radio.checkbox-black.radio-glow input:checked ~ label::before, .radio.radio-black.checkbox-glow input:checked ~ label::before, .radio.radio-black.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.7);
}

.checkbox.checkbox-black.checkbox-icon label i, .checkbox.radio-black.checkbox-icon label i, .radio.checkbox-black.checkbox-icon label i, .radio.radio-black.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-black label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-black input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-black .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #000000 !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-black.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-black, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-black {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=black]:before {
  background-color: #000000;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #000000;
}

/* Select2 black */
/* ---------------*/
select.select-light-black ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-black ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  color: #000000 !important;
}

/* dark Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.dark {
  color: #394C62 !important;
}

/* text with light dark */
.text-light-dark {
  color: rgba(57, 76, 98, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-dark {
  background-color: #394C62 !important;
}

.bg-dark .card-header,
.bg-dark .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-dark {
  background: rgba(57, 76, 98, 0.2) !important;
}

.bg-rgba-dark.alert {
  color: #394C62;
}

.bg-rgba-dark.alert.alert-dismissible .close {
  color: #394C62;
}

/* Alert dark */
/* ---------------- */
.alert.alert-dark {
  background: #394C62 !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(57, 76, 98, 0.4);
  border: none;
}

/* Border dark */
/* ---------------- */
.border-dark {
  border: 1px solid #394C62 !important;
}

.border-dark.alert {
  color: #394C62;
}

.border-dark.alert.alert-dismissible .close {
  color: #394C62;
}

.border-top-dark {
  border-top: 1px solid #394C62;
}

.border-bottom-dark {
  border-bottom: 1px solid #394C62;
}

.border-left-dark {
  border-left: 1px solid #394C62;
}

.border-right-dark {
  border-right: 1px solid #394C62;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-dark .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #394C62 !important;
}

/* Badges */
/* ------ */
.badge.badge-dark {
  background-color: #394C62;
}

.badge.badge-light-dark {
  color: #394C62 !important;
}

.badge.bg-dark.badge-glow, .badge.border-dark.badge-glow, .badge.badge-dark.badge-glow {
  box-shadow: 0px 0px 10px #394C62;
}

.badge-circle-dark {
  background-color: #394C62;
}

.badge-circle-light-dark {
  color: #394C62;
}

.overlay-dark {
  background: #394C62;
  background: rgba(57, 76, 98, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-dark {
  background-color: #394C62 !important;
  color: #fff;
}

.btn-dark:hover, .btn-dark.hover {
  color: #fff;
}

.btn-dark:hover.glow, .btn-dark.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(57, 76, 98, 0.6) !important;
}

.btn-dark:focus, .btn-dark:active, .btn-dark.active {
  color: #fff !important;
}

.btn-dark.glow {
  box-shadow: 0 2px 4px 0 rgba(57, 76, 98, 0.5) !important;
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff !important;
}

.btn-light-dark {
  color: #394C62 !important;
}

.btn-light-dark:hover, .btn-light-dark.hover {
  color: #fff !important;
}

.btn-light-dark:active, .btn-light-dark.active {
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-dark {
  border: 1px solid #394C62;
  background-color: transparent;
  color: #394C62 !important;
}

.btn-outline-dark:hover, .btn-outline-dark.hover {
  color: #fff !important;
}

.btn-outline-dark:active, .btn-outline-dark.active {
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-dark ~ .dropdown-menu .dropdown-item.active, .btn-outline-dark ~ .dropdown-menu .dropdown-item.active {
  background-color: #394C62;
  color: #fff;
}

.btn-dark ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-dark ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:hover i {
  color: #394C62;
}

.dropdown.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-dark ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-dark ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet dark */
.bullet.bullet-dark {
  background-color: #394C62;
}

/* For Pagination */
/* -------------- */
.pagination-dark .page-item.active .page-link, .pagination-dark .page-item.active .page-link:hover {
  background: #394C62 !important;
  color: #fff;
}

.pagination-dark .page-item.previous .page-link, .pagination-dark .page-item.next .page-link {
  color: #394C62;
}

.pagination-dark .page-item.previous .page-link:hover, .pagination-dark .page-item.next .page-link:hover {
  background: #394C62;
}

/* Progress Bars dark */
/* ---------------------- */
.progress-bar-dark .progress-bar {
  background-color: #394C62;
  box-shadow: 0 2px 6px 0 rgba(57, 76, 98, 0.6);
}

/* Chips dark */
/* ---------------- */
.chip-dark {
  background-color: #394C62 !important;
}

.chip-dark .chip-body {
  color: #fff !important;
}

/* Divider dark */
/* ---------------*/
.divider.divider-dark .divider-text:before, .divider.divider-dark .divider-text:after {
  border-color: #394C62 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-dark:before {
  background: #394C62 !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-dark + .custom-control-label:before, input[type="radio"].bg-dark + .custom-control-label:before {
  background-color: #394C62 !important;
}

.checkbox.checkbox-dark input:checked ~ label::before, .checkbox.radio-dark input:checked ~ label::before, .radio.checkbox-dark input:checked ~ label::before, .radio.radio-dark input:checked ~ label::before {
  background-color: #394C62;
  border-color: #394C62;
}

.checkbox.checkbox-dark input:checked ~ label::after, .checkbox.radio-dark input:checked ~ label::after, .radio.checkbox-dark input:checked ~ label::after, .radio.radio-dark input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-dark.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-dark.radio-glow input:checked ~ label::before, .checkbox.radio-dark.checkbox-glow input:checked ~ label::before, .checkbox.radio-dark.radio-glow input:checked ~ label::before, .radio.checkbox-dark.checkbox-glow input:checked ~ label::before, .radio.checkbox-dark.radio-glow input:checked ~ label::before, .radio.radio-dark.checkbox-glow input:checked ~ label::before, .radio.radio-dark.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(57, 76, 98, 0.7);
}

.checkbox.checkbox-dark.checkbox-icon label i, .checkbox.radio-dark.checkbox-icon label i, .radio.checkbox-dark.checkbox-icon label i, .radio.radio-dark.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-dark label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-dark input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-dark .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #394C62 !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-dark.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(57, 76, 98, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-dark, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-dark {
  box-shadow: 0 0 8px 0 rgba(57, 76, 98, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=dark]:before {
  background-color: #394C62;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #394C62;
}

/* Select2 dark */
/* ---------------*/
select.select-light-dark ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-dark ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  color: #394C62 !important;
}

/* light Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.light {
  color: #A3AFBD !important;
}

/* text with light light */
.text-light-light {
  color: rgba(163, 175, 189, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-light {
  background-color: #A3AFBD !important;
}

.bg-light .card-header,
.bg-light .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-light {
  background: rgba(163, 175, 189, 0.2) !important;
}

.bg-rgba-light.alert {
  color: #A3AFBD;
}

.bg-rgba-light.alert.alert-dismissible .close {
  color: #A3AFBD;
}

/* Alert light */
/* ---------------- */
.alert.alert-light {
  background: #A3AFBD !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(163, 175, 189, 0.4);
  border: none;
}

/* Border light */
/* ---------------- */
.border-light {
  border: 1px solid #A3AFBD !important;
}

.border-light.alert {
  color: #A3AFBD;
}

.border-light.alert.alert-dismissible .close {
  color: #A3AFBD;
}

.border-top-light {
  border-top: 1px solid #A3AFBD;
}

.border-bottom-light {
  border-bottom: 1px solid #A3AFBD;
}

.border-left-light {
  border-left: 1px solid #A3AFBD;
}

.border-right-light {
  border-right: 1px solid #A3AFBD;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-light .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #A3AFBD !important;
}

/* Badges */
/* ------ */
.badge.badge-light {
  background-color: #A3AFBD;
}

.badge.badge-light-light {
  color: #A3AFBD !important;
}

.badge.bg-light.badge-glow, .badge.border-light.badge-glow, .badge.badge-light.badge-glow {
  box-shadow: 0px 0px 10px #A3AFBD;
}

.badge-circle-light {
  background-color: #A3AFBD;
}

.badge-circle-light-light {
  color: #A3AFBD;
}

.overlay-light {
  background: #A3AFBD;
  background: rgba(163, 175, 189, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-light {
  background-color: #A3AFBD !important;
  color: #fff;
}

.btn-light:hover, .btn-light.hover {
  color: #fff;
}

.btn-light:hover.glow, .btn-light.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(163, 175, 189, 0.6) !important;
}

.btn-light:focus, .btn-light:active, .btn-light.active {
  color: #fff !important;
}

.btn-light.glow {
  box-shadow: 0 2px 4px 0 rgba(163, 175, 189, 0.5) !important;
}

.btn-light:disabled, .btn-light.disabled {
  color: #fff !important;
}

.btn-light-light {
  color: #A3AFBD !important;
}

.btn-light-light:hover, .btn-light-light.hover {
  color: #fff !important;
}

.btn-light-light:active, .btn-light-light.active {
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-light {
  border: 1px solid #A3AFBD;
  background-color: transparent;
  color: #A3AFBD !important;
}

.btn-outline-light:hover, .btn-outline-light.hover {
  color: #fff !important;
}

.btn-outline-light:active, .btn-outline-light.active {
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-light ~ .dropdown-menu .dropdown-item.active, .btn-outline-light ~ .dropdown-menu .dropdown-item.active {
  background-color: #A3AFBD;
  color: #fff;
}

.btn-light ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-light ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:hover i {
  color: #A3AFBD;
}

.dropdown.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-light ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-light ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet light */
.bullet.bullet-light {
  background-color: #A3AFBD;
}

/* For Pagination */
/* -------------- */
.pagination-light .page-item.active .page-link, .pagination-light .page-item.active .page-link:hover {
  background: #A3AFBD !important;
  color: #fff;
}

.pagination-light .page-item.previous .page-link, .pagination-light .page-item.next .page-link {
  color: #A3AFBD;
}

.pagination-light .page-item.previous .page-link:hover, .pagination-light .page-item.next .page-link:hover {
  background: #A3AFBD;
}

/* Progress Bars light */
/* ---------------------- */
.progress-bar-light .progress-bar {
  background-color: #A3AFBD;
  box-shadow: 0 2px 6px 0 rgba(163, 175, 189, 0.6);
}

/* Chips light */
/* ---------------- */
.chip-light {
  background-color: #A3AFBD !important;
}

.chip-light .chip-body {
  color: #fff !important;
}

/* Divider light */
/* ---------------*/
.divider.divider-light .divider-text:before, .divider.divider-light .divider-text:after {
  border-color: #A3AFBD !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-light:before {
  background: #A3AFBD !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-light + .custom-control-label:before, input[type="radio"].bg-light + .custom-control-label:before {
  background-color: #A3AFBD !important;
}

.checkbox.checkbox-light input:checked ~ label::before, .checkbox.radio-light input:checked ~ label::before, .radio.checkbox-light input:checked ~ label::before, .radio.radio-light input:checked ~ label::before {
  background-color: #A3AFBD;
  border-color: #A3AFBD;
}

.checkbox.checkbox-light input:checked ~ label::after, .checkbox.radio-light input:checked ~ label::after, .radio.checkbox-light input:checked ~ label::after, .radio.radio-light input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-light.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-light.radio-glow input:checked ~ label::before, .checkbox.radio-light.checkbox-glow input:checked ~ label::before, .checkbox.radio-light.radio-glow input:checked ~ label::before, .radio.checkbox-light.checkbox-glow input:checked ~ label::before, .radio.checkbox-light.radio-glow input:checked ~ label::before, .radio.radio-light.checkbox-glow input:checked ~ label::before, .radio.radio-light.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(163, 175, 189, 0.7);
}

.checkbox.checkbox-light.checkbox-icon label i, .checkbox.radio-light.checkbox-icon label i, .radio.checkbox-light.checkbox-icon label i, .radio.radio-light.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-light label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-light input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-light .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #A3AFBD !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-light.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(163, 175, 189, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-light, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-light {
  box-shadow: 0 0 8px 0 rgba(163, 175, 189, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=light]:before {
  background-color: #A3AFBD;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #A3AFBD;
}

/* Select2 light */
/* ---------------*/
select.select-light-light ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-light ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  color: #A3AFBD !important;
}

.primary.lighten-5 {
  color: #ceddfa !important;
}

.bg-primary.bg-lighten-5 {
  background-color: #ceddfa !important;
}

.btn-primary.btn-lighten-5 {
  border-color: #2c6de9 !important;
  background-color: #ceddfa !important;
}

.btn-primary.btn-lighten-5:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-lighten-5:focus, .btn-primary.btn-lighten-5:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-lighten-5 {
  border-color: #ceddfa !important;
  color: #ceddfa !important;
}

.btn-outline-primary.btn-outline-lighten-5:hover {
  background-color: #ceddfa !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ceddfa !important;
}

.border-primary.border-lighten-5 {
  border: 1px solid #ceddfa !important;
}

.border-top-primary.border-top-lighten-5 {
  border-top: 1px solid #ceddfa !important;
}

.border-bottom-primary.border-bottom-lighten-5 {
  border-bottom: 1px solid #ceddfa !important;
}

.border-left-primary.border-left-lighten-5 {
  border-left: 1px solid #ceddfa !important;
}

.border-right-primary.border-right-lighten-5 {
  border-right: 1px solid #ceddfa !important;
}

.overlay-primary.overlay-lighten-5 {
  background: #ceddfa;
  background: rgba(206, 221, 250, 0.6);
}

.primary.lighten-4 {
  color: #b6cdf8 !important;
}

.bg-primary.bg-lighten-4 {
  background-color: #b6cdf8 !important;
}

.btn-primary.btn-lighten-4 {
  border-color: #2c6de9 !important;
  background-color: #b6cdf8 !important;
}

.btn-primary.btn-lighten-4:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-lighten-4:focus, .btn-primary.btn-lighten-4:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-lighten-4 {
  border-color: #b6cdf8 !important;
  color: #b6cdf8 !important;
}

.btn-outline-primary.btn-outline-lighten-4:hover {
  background-color: #b6cdf8 !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b6cdf8 !important;
}

.border-primary.border-lighten-4 {
  border: 1px solid #b6cdf8 !important;
}

.border-top-primary.border-top-lighten-4 {
  border-top: 1px solid #b6cdf8 !important;
}

.border-bottom-primary.border-bottom-lighten-4 {
  border-bottom: 1px solid #b6cdf8 !important;
}

.border-left-primary.border-left-lighten-4 {
  border-left: 1px solid #b6cdf8 !important;
}

.border-right-primary.border-right-lighten-4 {
  border-right: 1px solid #b6cdf8 !important;
}

.overlay-primary.overlay-lighten-4 {
  background: #b6cdf8;
  background: rgba(182, 205, 248, 0.6);
}

.primary.lighten-3 {
  color: #9fbdf5 !important;
}

.bg-primary.bg-lighten-3 {
  background-color: #9fbdf5 !important;
}

.btn-primary.btn-lighten-3 {
  border-color: #2c6de9 !important;
  background-color: #9fbdf5 !important;
}

.btn-primary.btn-lighten-3:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-lighten-3:focus, .btn-primary.btn-lighten-3:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-lighten-3 {
  border-color: #9fbdf5 !important;
  color: #9fbdf5 !important;
}

.btn-outline-primary.btn-outline-lighten-3:hover {
  background-color: #9fbdf5 !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9fbdf5 !important;
}

.border-primary.border-lighten-3 {
  border: 1px solid #9fbdf5 !important;
}

.border-top-primary.border-top-lighten-3 {
  border-top: 1px solid #9fbdf5 !important;
}

.border-bottom-primary.border-bottom-lighten-3 {
  border-bottom: 1px solid #9fbdf5 !important;
}

.border-left-primary.border-left-lighten-3 {
  border-left: 1px solid #9fbdf5 !important;
}

.border-right-primary.border-right-lighten-3 {
  border-right: 1px solid #9fbdf5 !important;
}

.overlay-primary.overlay-lighten-3 {
  background: #9fbdf5;
  background: rgba(159, 189, 245, 0.6);
}

.primary.lighten-2 {
  color: #88adf3 !important;
}

.bg-primary.bg-lighten-2 {
  background-color: #88adf3 !important;
}

.btn-primary.btn-lighten-2 {
  border-color: #2c6de9 !important;
  background-color: #88adf3 !important;
}

.btn-primary.btn-lighten-2:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-lighten-2:focus, .btn-primary.btn-lighten-2:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-lighten-2 {
  border-color: #88adf3 !important;
  color: #88adf3 !important;
}

.btn-outline-primary.btn-outline-lighten-2:hover {
  background-color: #88adf3 !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #88adf3 !important;
}

.border-primary.border-lighten-2 {
  border: 1px solid #88adf3 !important;
}

.border-top-primary.border-top-lighten-2 {
  border-top: 1px solid #88adf3 !important;
}

.border-bottom-primary.border-bottom-lighten-2 {
  border-bottom: 1px solid #88adf3 !important;
}

.border-left-primary.border-left-lighten-2 {
  border-left: 1px solid #88adf3 !important;
}

.border-right-primary.border-right-lighten-2 {
  border-right: 1px solid #88adf3 !important;
}

.overlay-primary.overlay-lighten-2 {
  background: #88adf3;
  background: rgba(136, 173, 243, 0.6);
}

.primary.lighten-1 {
  color: #719df0 !important;
}

.bg-primary.bg-lighten-1 {
  background-color: #719df0 !important;
}

.btn-primary.btn-lighten-1 {
  border-color: #2c6de9 !important;
  background-color: #719df0 !important;
}

.btn-primary.btn-lighten-1:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-lighten-1:focus, .btn-primary.btn-lighten-1:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-lighten-1 {
  border-color: #719df0 !important;
  color: #719df0 !important;
}

.btn-outline-primary.btn-outline-lighten-1:hover {
  background-color: #719df0 !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #719df0 !important;
}

.border-primary.border-lighten-1 {
  border: 1px solid #719df0 !important;
}

.border-top-primary.border-top-lighten-1 {
  border-top: 1px solid #719df0 !important;
}

.border-bottom-primary.border-bottom-lighten-1 {
  border-bottom: 1px solid #719df0 !important;
}

.border-left-primary.border-left-lighten-1 {
  border-left: 1px solid #719df0 !important;
}

.border-right-primary.border-right-lighten-1 {
  border-right: 1px solid #719df0 !important;
}

.overlay-primary.overlay-lighten-1 {
  background: #719df0;
  background: rgba(113, 157, 240, 0.6);
}

/* primary Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.primary {
  color: #33CC76 !important;
}

/* text with light primary */
.text-light-primary {
  color: rgba(90, 141, 238, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-primary {
  background-color: #33CC76 !important;
  /* background-color: #5AEEA9 !important; */
}

.bg-primary .card-header,
.bg-primary .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-primary {
  background: rgba(90, 141, 238, 0.2) !important;
}

.bg-rgba-primary.alert {
  color: #5A8DEE;
}

.bg-rgba-primary.alert.alert-dismissible .close {
  color: #5A8DEE;
}

/* Alert primary */
/* ---------------- */
.alert.alert-primary {
  background: #5A8DEE !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(90, 141, 238, 0.4);
  border: none;
}

/* Border primary */
/* ---------------- */
.border-primary {
  border: 1px solid #5A8DEE !important;
}

.border-primary .select2-selection__arrow b {
  border-color: #2c6de9 !important;
}

.border-primary.alert {
  color: #5A8DEE;
}

.border-primary.alert.alert-dismissible .close {
  color: #5A8DEE;
}

.border-top-primary {
  border-top: 1px solid #5A8DEE;
}

.border-bottom-primary {
  border-bottom: 1px solid #5A8DEE;
}

.border-left-primary {
  border-left: 1px solid #5A8DEE;
}

.border-right-primary {
  border-right: 1px solid #5A8DEE;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-primary .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #5A8DEE !important;
}

/* Badges */
/* ------ */
.badge.badge-primary {
  background-color: #5A8DEE;
}

.badge.badge-light-primary {
  background-color: #E2ECFF;
  color: #5A8DEE !important;
}

.badge.bg-primary.badge-glow, .badge.border-primary.badge-glow, .badge.badge-primary.badge-glow {
  box-shadow: 0px 0px 10px #5A8DEE;
}

.badge-circle-primary {
  background-color: #5A8DEE;
}

.badge-circle-light-primary {
  background-color: #E2ECFF;
  color: #5A8DEE;
}

.overlay-primary {
  background: #5A8DEE;
  background: rgba(90, 141, 238, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-primary {
  border-color: #2ce999 !important;
  background-color: #5AEEA2 !important;
  color: #fff;
}

.btn-primary:hover, .btn-primary.hover {
  background-color: #71f0af !important;
  color: #fff;
}

.btn-primary:hover.glow, .btn-primary.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(90, 238, 189, 0.6) !important;
}

.btn-primary:focus, .btn-primary:active, .btn-primary.active {
  background-color: #43ec8d !important;
  color: #fff !important;
}

.btn-primary.glow {
  box-shadow: 0 4px 12px 0 rgba(90, 238, 189, 0.5) !important;
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff !important;
}

.btn-light-primary {
  background-color: #E2ECFF;
  color: #5A8DEE !important;
}

.btn-light-primary:hover, .btn-light-primary.hover {
  background-color: #719df0 !important;
  color: #fff !important;
}

.btn-light-primary:active, .btn-light-primary.active {
  background-color: #437dec !important;
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-primary {
  border: 1px solid #5A8DEE;
  background-color: transparent;
  color: #5A8DEE !important;
}

.btn-outline-primary:hover, .btn-outline-primary.hover {
  background-color: #719df0 !important;
  color: #fff !important;
}

.btn-outline-primary:active, .btn-outline-primary.active {
  background-color: #437dec !important;
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-primary ~ .dropdown-menu .dropdown-item.active, .btn-outline-primary ~ .dropdown-menu .dropdown-item.active {
  background-color: #5A8DEE;
  color: #fff;
}

.btn-primary ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-primary ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:hover i {
  color: #5A8DEE;
}

.dropdown.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-primary ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-primary ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet primary */
.bullet.bullet-primary {
  background-color: #5A8DEE;
}

/* For Pagination */
/* -------------- */
.pagination-primary .page-item.active .page-link, .pagination-primary .page-item.active .page-link:hover {
  background: #5A8DEE !important;
  color: #fff;
}

.pagination-primary .page-item .page-link:hover {
  background-color: #E2ECFF !important;
}

.pagination-primary .page-item.previous .page-link, .pagination-primary .page-item.next .page-link {
  color: #5A8DEE;
}

.pagination-primary .page-item.previous .page-link:hover, .pagination-primary .page-item.next .page-link:hover {
  background: #5A8DEE;
}

/* Progress Bars primary */
/* ---------------------- */
.progress-bar-primary .progress-bar {
  background-color: #5A8DEE;
  box-shadow: 0 2px 6px 0 rgba(90, 141, 238, 0.6);
}

/* Chips primary */
/* ---------------- */
.chip-primary {
  background-color: #5A8DEE !important;
}

.chip-primary .chip-body {
  color: #fff !important;
}

/* Divider primary */
/* ---------------*/
.divider.divider-primary .divider-text:before, .divider.divider-primary .divider-text:after {
  border-color: #5A8DEE !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-primary:before {
  background: #5A8DEE !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-primary + .custom-control-label:before, input[type="radio"].bg-primary + .custom-control-label:before {
  background-color: #5A8DEE !important;
  border-color: #2c6de9 !important;
}

.checkbox.checkbox-primary input:checked ~ label::before, .checkbox.radio-primary input:checked ~ label::before, .radio.checkbox-primary input:checked ~ label::before, .radio.radio-primary input:checked ~ label::before {
  background-color: #5A8DEE;
  border-color: #5A8DEE;
}

.checkbox.checkbox-primary input:checked ~ label::after, .checkbox.radio-primary input:checked ~ label::after, .radio.checkbox-primary input:checked ~ label::after, .radio.radio-primary input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-primary.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-primary.radio-glow input:checked ~ label::before, .checkbox.radio-primary.checkbox-glow input:checked ~ label::before, .checkbox.radio-primary.radio-glow input:checked ~ label::before, .radio.checkbox-primary.checkbox-glow input:checked ~ label::before, .radio.checkbox-primary.radio-glow input:checked ~ label::before, .radio.radio-primary.checkbox-glow input:checked ~ label::before, .radio.radio-primary.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(90, 141, 238, 0.7);
}

.checkbox.checkbox-primary.checkbox-icon label i, .checkbox.radio-primary.checkbox-icon label i, .radio.checkbox-primary.checkbox-icon label i, .radio.radio-primary.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-primary label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-primary input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-primary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5A8DEE !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-primary.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(90, 141, 238, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-primary, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-primary {
  box-shadow: 0 0 8px 0 rgba(90, 141, 238, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=primary]:before {
  background-color: #5A8DEE;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #5A8DEE;
}

/* Select2 primary */
/* ---------------*/
select.select-light-primary ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-primary ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  background-color: #E2ECFF !important;
  color: #5A8DEE !important;
}

.primary.darken-1 {
  color: #437dec !important;
}

.bg-primary.bg-darken-1 {
  background-color: #437dec !important;
}

.btn-primary.btn-darken-1 {
  border-color: #2c6de9 !important;
  background-color: #437dec !important;
}

.btn-primary.btn-darken-1:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-darken-1:focus, .btn-primary.btn-darken-1:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-darken-1 {
  border-color: #437dec !important;
  color: #437dec !important;
}

.btn-outline-primary.btn-outline-darken-1:hover {
  background-color: #437dec !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #437dec !important;
}

.border-primary.border-darken-1 {
  border: 1px solid #437dec !important;
}

.border-top-primary.border-top-darken-1 {
  border-top: 1px solid #437dec !important;
}

.border-bottom-primary.border-bottom-darken-1 {
  border-bottom: 1px solid #437dec !important;
}

.border-left-primary.border-left-darken-1 {
  border-left: 1px solid #437dec !important;
}

.border-right-primary.border-right-darken-1 {
  border-right: 1px solid #437dec !important;
}

.overlay-primary.overlay-darken-1 {
  background: #437dec;
  background: rgba(67, 125, 236, 0.6);
}

.primary.darken-2 {
  color: #2c6de9 !important;
}

.bg-primary.bg-darken-2 {
  background-color: #2c6de9 !important;
}

.btn-primary.btn-darken-2 {
  border-color: #2c6de9 !important;
  background-color: #2c6de9 !important;
}

.btn-primary.btn-darken-2:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-darken-2:focus, .btn-primary.btn-darken-2:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-darken-2 {
  border-color: #2c6de9 !important;
  color: #2c6de9 !important;
}

.btn-outline-primary.btn-outline-darken-2:hover {
  background-color: #2c6de9 !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2c6de9 !important;
}

.border-primary.border-darken-2 {
  border: 1px solid #2c6de9 !important;
}

.border-top-primary.border-top-darken-2 {
  border-top: 1px solid #2c6de9 !important;
}

.border-bottom-primary.border-bottom-darken-2 {
  border-bottom: 1px solid #2c6de9 !important;
}

.border-left-primary.border-left-darken-2 {
  border-left: 1px solid #2c6de9 !important;
}

.border-right-primary.border-right-darken-2 {
  border-right: 1px solid #2c6de9 !important;
}

.overlay-primary.overlay-darken-2 {
  background: #2c6de9;
  background: rgba(44, 109, 233, 0.6);
}

.primary.darken-3 {
  color: #175ee4 !important;
}

.bg-primary.bg-darken-3 {
  background-color: #175ee4 !important;
}

.btn-primary.btn-darken-3 {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-darken-3:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-darken-3:focus, .btn-primary.btn-darken-3:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-darken-3 {
  border-color: #175ee4 !important;
  color: #175ee4 !important;
}

.btn-outline-primary.btn-outline-darken-3:hover {
  background-color: #175ee4 !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #175ee4 !important;
}

.border-primary.border-darken-3 {
  border: 1px solid #175ee4 !important;
}

.border-top-primary.border-top-darken-3 {
  border-top: 1px solid #175ee4 !important;
}

.border-bottom-primary.border-bottom-darken-3 {
  border-bottom: 1px solid #175ee4 !important;
}

.border-left-primary.border-left-darken-3 {
  border-left: 1px solid #175ee4 !important;
}

.border-right-primary.border-right-darken-3 {
  border-right: 1px solid #175ee4 !important;
}

.overlay-primary.overlay-darken-3 {
  background: #175ee4;
  background: rgba(23, 94, 228, 0.6);
}

.primary.darken-4 {
  color: #1554cd !important;
}

.bg-primary.bg-darken-4 {
  background-color: #1554cd !important;
}

.btn-primary.btn-darken-4 {
  border-color: #2c6de9 !important;
  background-color: #1554cd !important;
}

.btn-primary.btn-darken-4:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-darken-4:focus, .btn-primary.btn-darken-4:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-darken-4 {
  border-color: #1554cd !important;
  color: #1554cd !important;
}

.btn-outline-primary.btn-outline-darken-4:hover {
  background-color: #1554cd !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1554cd !important;
}

.border-primary.border-darken-4 {
  border: 1px solid #1554cd !important;
}

.border-top-primary.border-top-darken-4 {
  border-top: 1px solid #1554cd !important;
}

.border-bottom-primary.border-bottom-darken-4 {
  border-bottom: 1px solid #1554cd !important;
}

.border-left-primary.border-left-darken-4 {
  border-left: 1px solid #1554cd !important;
}

.border-right-primary.border-right-darken-4 {
  border-right: 1px solid #1554cd !important;
}

.overlay-primary.overlay-darken-4 {
  background: #1554cd;
  background: rgba(21, 84, 205, 0.6);
}

.primary.accent-1 {
  color: #BDFDFF !important;
}

.bg-primary.bg-accent-1 {
  background-color: #BDFDFF !important;
}

.btn-primary.btn-accent-1 {
  border-color: #2c6de9 !important;
  background-color: #BDFDFF !important;
}

.btn-primary.btn-accent-1:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-accent-1:focus, .btn-primary.btn-accent-1:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-accent-1 {
  border-color: #BDFDFF !important;
  color: #BDFDFF !important;
}

.btn-outline-primary.btn-outline-accent-1:hover {
  background-color: #BDFDFF !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BDFDFF !important;
}

.border-primary.border-accent-1 {
  border: 1px solid #BDFDFF !important;
}

.border-top-primary.border-top-accent-1 {
  border-top: 1px solid #BDFDFF !important;
}

.border-bottom-primary.border-bottom-accent-1 {
  border-bottom: 1px solid #BDFDFF !important;
}

.border-left-primary.border-left-accent-1 {
  border-left: 1px solid #BDFDFF !important;
}

.border-right-primary.border-right-accent-1 {
  border-right: 1px solid #BDFDFF !important;
}

.overlay-primary.overlay-accent-1 {
  background: #BDFDFF;
  background: rgba(189, 253, 255, 0.6);
}

.primary.accent-2 {
  color: #8AFBFF !important;
}

.bg-primary.bg-accent-2 {
  background-color: #8AFBFF !important;
}

.btn-primary.btn-accent-2 {
  border-color: #2c6de9 !important;
  background-color: #8AFBFF !important;
}

.btn-primary.btn-accent-2:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-accent-2:focus, .btn-primary.btn-accent-2:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-accent-2 {
  border-color: #8AFBFF !important;
  color: #8AFBFF !important;
}

.btn-outline-primary.btn-outline-accent-2:hover {
  background-color: #8AFBFF !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8AFBFF !important;
}

.border-primary.border-accent-2 {
  border: 1px solid #8AFBFF !important;
}

.border-top-primary.border-top-accent-2 {
  border-top: 1px solid #8AFBFF !important;
}

.border-bottom-primary.border-bottom-accent-2 {
  border-bottom: 1px solid #8AFBFF !important;
}

.border-left-primary.border-left-accent-2 {
  border-left: 1px solid #8AFBFF !important;
}

.border-right-primary.border-right-accent-2 {
  border-right: 1px solid #8AFBFF !important;
}

.overlay-primary.overlay-accent-2 {
  background: #8AFBFF;
  background: rgba(138, 251, 255, 0.6);
}

.primary.accent-3 {
  color: #57FAFF !important;
}

.bg-primary.bg-accent-3 {
  background-color: #57FAFF !important;
}

.btn-primary.btn-accent-3 {
  border-color: #2c6de9 !important;
  background-color: #57FAFF !important;
}

.btn-primary.btn-accent-3:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-accent-3:focus, .btn-primary.btn-accent-3:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-accent-3 {
  border-color: #57FAFF !important;
  color: #57FAFF !important;
}

.btn-outline-primary.btn-outline-accent-3:hover {
  background-color: #57FAFF !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #57FAFF !important;
}

.border-primary.border-accent-3 {
  border: 1px solid #57FAFF !important;
}

.border-top-primary.border-top-accent-3 {
  border-top: 1px solid #57FAFF !important;
}

.border-bottom-primary.border-bottom-accent-3 {
  border-bottom: 1px solid #57FAFF !important;
}

.border-left-primary.border-left-accent-3 {
  border-left: 1px solid #57FAFF !important;
}

.border-right-primary.border-right-accent-3 {
  border-right: 1px solid #57FAFF !important;
}

.overlay-primary.overlay-accent-3 {
  background: #57FAFF;
  background: rgba(87, 250, 255, 0.6);
}

.primary.accent-4 {
  color: #3DF9FF !important;
}

.bg-primary.bg-accent-4 {
  background-color: #3DF9FF !important;
}

.btn-primary.btn-accent-4 {
  border-color: #2c6de9 !important;
  background-color: #3DF9FF !important;
}

.btn-primary.btn-accent-4:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-accent-4:focus, .btn-primary.btn-accent-4:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-accent-4 {
  border-color: #3DF9FF !important;
  color: #3DF9FF !important;
}

.btn-outline-primary.btn-outline-accent-4:hover {
  background-color: #3DF9FF !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3DF9FF !important;
}

.border-primary.border-accent-4 {
  border: 1px solid #3DF9FF !important;
}

.border-top-primary.border-top-accent-4 {
  border-top: 1px solid #3DF9FF !important;
}

.border-bottom-primary.border-bottom-accent-4 {
  border-bottom: 1px solid #3DF9FF !important;
}

.border-left-primary.border-left-accent-4 {
  border-left: 1px solid #3DF9FF !important;
}

.border-right-primary.border-right-accent-4 {
  border-right: 1px solid #3DF9FF !important;
}

.overlay-primary.overlay-accent-4 {
  background: #3DF9FF;
  background: rgba(61, 249, 255, 0.6);
}

.primary.light {
  color: #E2ECFF !important;
}

.bg-primary.bg-light {
  background-color: #E2ECFF !important;
}

.btn-primary.btn-light {
  border-color: #2c6de9 !important;
  background-color: #E2ECFF !important;
}

.btn-primary.btn-light:hover {
  border-color: #2c6de9 !important;
  background-color: #175ee4 !important;
}

.btn-primary.btn-light:focus, .btn-primary.btn-light:active {
  border-color: #175ee4 !important;
  background-color: #1554cd !important;
}

.btn-outline-primary.btn-outline-light {
  border-color: #E2ECFF !important;
  color: #E2ECFF !important;
}

.btn-outline-primary.btn-outline-light:hover {
  background-color: #E2ECFF !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E2ECFF !important;
}

.border-primary.border-light {
  border: 1px solid #E2ECFF !important;
}

.border-top-primary.border-top-light {
  border-top: 1px solid #E2ECFF !important;
}

.border-bottom-primary.border-bottom-light {
  border-bottom: 1px solid #E2ECFF !important;
}

.border-left-primary.border-left-light {
  border-left: 1px solid #E2ECFF !important;
}

.border-right-primary.border-right-light {
  border-right: 1px solid #E2ECFF !important;
}

.overlay-primary.overlay-light {
  background: #E2ECFF;
  background: rgba(226, 236, 255, 0.6);
}

.secondary.lighten-5 {
  color: #879fba !important;
}

.bg-secondary.bg-lighten-5 {
  background-color: #879fba !important;
}

.btn-secondary.btn-lighten-5 {
  border-color: #34465b !important;
  background-color: #879fba !important;
}

.btn-secondary.btn-lighten-5:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-lighten-5:focus, .btn-secondary.btn-lighten-5:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-lighten-5 {
  border-color: #879fba !important;
  color: #879fba !important;
}

.btn-outline-secondary.btn-outline-lighten-5:hover {
  background-color: #879fba !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #879fba !important;
}

.border-secondary.border-lighten-5 {
  border: 1px solid #879fba !important;
}

.border-top-secondary.border-top-lighten-5 {
  border-top: 1px solid #879fba !important;
}

.border-bottom-secondary.border-bottom-lighten-5 {
  border-bottom: 1px solid #879fba !important;
}

.border-left-secondary.border-left-lighten-5 {
  border-left: 1px solid #879fba !important;
}

.border-right-secondary.border-right-lighten-5 {
  border-right: 1px solid #879fba !important;
}

.overlay-secondary.overlay-lighten-5 {
  background: #879fba;
  background: rgba(135, 159, 186, 0.6);
}

.secondary.lighten-4 {
  color: #7792b1 !important;
}

.bg-secondary.bg-lighten-4 {
  background-color: #7792b1 !important;
}

.btn-secondary.btn-lighten-4 {
  border-color: #34465b !important;
  background-color: #7792b1 !important;
}

.btn-secondary.btn-lighten-4:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-lighten-4:focus, .btn-secondary.btn-lighten-4:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-lighten-4 {
  border-color: #7792b1 !important;
  color: #7792b1 !important;
}

.btn-outline-secondary.btn-outline-lighten-4:hover {
  background-color: #7792b1 !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7792b1 !important;
}

.border-secondary.border-lighten-4 {
  border: 1px solid #7792b1 !important;
}

.border-top-secondary.border-top-lighten-4 {
  border-top: 1px solid #7792b1 !important;
}

.border-bottom-secondary.border-bottom-lighten-4 {
  border-bottom: 1px solid #7792b1 !important;
}

.border-left-secondary.border-left-lighten-4 {
  border-left: 1px solid #7792b1 !important;
}

.border-right-secondary.border-right-lighten-4 {
  border-right: 1px solid #7792b1 !important;
}

.overlay-secondary.overlay-lighten-4 {
  background: #7792b1;
  background: rgba(119, 146, 177, 0.6);
}

.secondary.lighten-3 {
  color: #6785a7 !important;
}

.bg-secondary.bg-lighten-3 {
  background-color: #6785a7 !important;
}

.btn-secondary.btn-lighten-3 {
  border-color: #34465b !important;
  background-color: #6785a7 !important;
}

.btn-secondary.btn-lighten-3:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-lighten-3:focus, .btn-secondary.btn-lighten-3:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-lighten-3 {
  border-color: #6785a7 !important;
  color: #6785a7 !important;
}

.btn-outline-secondary.btn-outline-lighten-3:hover {
  background-color: #6785a7 !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6785a7 !important;
}

.border-secondary.border-lighten-3 {
  border: 1px solid #6785a7 !important;
}

.border-top-secondary.border-top-lighten-3 {
  border-top: 1px solid #6785a7 !important;
}

.border-bottom-secondary.border-bottom-lighten-3 {
  border-bottom: 1px solid #6785a7 !important;
}

.border-left-secondary.border-left-lighten-3 {
  border-left: 1px solid #6785a7 !important;
}

.border-right-secondary.border-right-lighten-3 {
  border-right: 1px solid #6785a7 !important;
}

.overlay-secondary.overlay-lighten-3 {
  background: #6785a7;
  background: rgba(103, 133, 167, 0.6);
}

.secondary.lighten-2 {
  color: #5a789b !important;
}

.bg-secondary.bg-lighten-2 {
  background-color: #5a789b !important;
}

.btn-secondary.btn-lighten-2 {
  border-color: #34465b !important;
  background-color: #5a789b !important;
}

.btn-secondary.btn-lighten-2:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-lighten-2:focus, .btn-secondary.btn-lighten-2:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-lighten-2 {
  border-color: #5a789b !important;
  color: #5a789b !important;
}

.btn-outline-secondary.btn-outline-lighten-2:hover {
  background-color: #5a789b !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5a789b !important;
}

.border-secondary.border-lighten-2 {
  border: 1px solid #5a789b !important;
}

.border-top-secondary.border-top-lighten-2 {
  border-top: 1px solid #5a789b !important;
}

.border-bottom-secondary.border-bottom-lighten-2 {
  border-bottom: 1px solid #5a789b !important;
}

.border-left-secondary.border-left-lighten-2 {
  border-left: 1px solid #5a789b !important;
}

.border-right-secondary.border-right-lighten-2 {
  border-right: 1px solid #5a789b !important;
}

.overlay-secondary.overlay-lighten-2 {
  background: #5a789b;
  background: rgba(90, 120, 155, 0.6);
}

.secondary.lighten-1 {
  color: #506b8b !important;
}

.bg-secondary.bg-lighten-1 {
  background-color: #506b8b !important;
}

.btn-secondary.btn-lighten-1 {
  border-color: #34465b !important;
  background-color: #506b8b !important;
}

.btn-secondary.btn-lighten-1:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-lighten-1:focus, .btn-secondary.btn-lighten-1:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-lighten-1 {
  border-color: #506b8b !important;
  color: #506b8b !important;
}

.btn-outline-secondary.btn-outline-lighten-1:hover {
  background-color: #506b8b !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #506b8b !important;
}

.border-secondary.border-lighten-1 {
  border: 1px solid #506b8b !important;
}

.border-top-secondary.border-top-lighten-1 {
  border-top: 1px solid #506b8b !important;
}

.border-bottom-secondary.border-bottom-lighten-1 {
  border-bottom: 1px solid #506b8b !important;
}

.border-left-secondary.border-left-lighten-1 {
  border-left: 1px solid #506b8b !important;
}

.border-right-secondary.border-right-lighten-1 {
  border-right: 1px solid #506b8b !important;
}

.overlay-secondary.overlay-lighten-1 {
  background: #506b8b;
  background: rgba(80, 107, 139, 0.6);
}

/* secondary Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.secondary {
  color: #475F7B !important;
}

/* text with light secondary */
.text-light-secondary {
  color: rgba(71, 95, 123, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-secondary {
  background-color: #475F7B !important;
}

.bg-secondary .card-header,
.bg-secondary .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-secondary {
  background: rgba(71, 95, 123, 0.2) !important;
}

.bg-rgba-secondary.alert {
  color: #475F7B;
}

.bg-rgba-secondary.alert.alert-dismissible .close {
  color: #475F7B;
}

/* Alert secondary */
/* ---------------- */
.alert.alert-secondary {
  background: #475F7B !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(71, 95, 123, 0.4);
  border: none;
}

/* Border secondary */
/* ---------------- */
.border-secondary {
  border: 1px solid #475F7B !important;
}

.border-secondary .select2-selection__arrow b {
  border-color: #34465b !important;
}

.border-secondary.alert {
  color: #475F7B;
}

.border-secondary.alert.alert-dismissible .close {
  color: #475F7B;
}

.border-top-secondary {
  border-top: 1px solid #475F7B;
}

.border-bottom-secondary {
  border-bottom: 1px solid #475F7B;
}

.border-left-secondary {
  border-left: 1px solid #475F7B;
}

.border-right-secondary {
  border-right: 1px solid #475F7B;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-secondary .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #475F7B !important;
}

/* Badges */
/* ------ */
.badge.badge-secondary {
  background-color: #475F7B;
}

.badge.badge-light-secondary {
  background-color: #E6EAEE;
  color: #475F7B !important;
}

.badge.bg-secondary.badge-glow, .badge.border-secondary.badge-glow, .badge.badge-secondary.badge-glow {
  box-shadow: 0px 0px 10px #475F7B;
}

.badge-circle-secondary {
  background-color: #475F7B;
}

.badge-circle-light-secondary {
  background-color: #E6EAEE;
  color: #475F7B;
}

.overlay-secondary {
  background: #475F7B;
  background: rgba(71, 95, 123, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-secondary {
  border-color: #34465b !important;
  background-color: #475F7B !important;
  color: #fff;
}

.btn-secondary:hover, .btn-secondary.hover {
  background-color: #506b8b !important;
  color: #fff;
}

.btn-secondary:hover.glow, .btn-secondary.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(71, 95, 123, 0.6) !important;
}

.btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
  background-color: #3e536b !important;
  color: #fff !important;
}

.btn-secondary.glow {
  box-shadow: 0 2px 4px 0 rgba(71, 95, 123, 0.5) !important;
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff !important;
}

.btn-light-secondary {
  background-color: #E6EEEA;
  color: #475F7B !important;
}

.btn-light-secondary:hover, .btn-light-secondary.hover {
  background-color: #508b85 !important;
  color: #fff !important;
}

.btn-light-secondary:active, .btn-light-secondary.active {
  background-color: #3e6b56 !important;
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-secondary {
  border: 1px solid #475F7B;
  background-color: transparent;
  color: #475F7B !important;
}

.btn-outline-secondary:hover, .btn-outline-secondary.hover {
  background-color: #506b8b !important;
  color: #fff !important;
}

.btn-outline-secondary:active, .btn-outline-secondary.active {
  background-color: #3e536b !important;
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-secondary ~ .dropdown-menu .dropdown-item.active, .btn-outline-secondary ~ .dropdown-menu .dropdown-item.active {
  background-color: #475F7B;
  color: #fff;
}

.btn-secondary ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-secondary ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:hover i {
  color: #475F7B;
}

.dropdown.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-secondary ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-secondary ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet secondary */
.bullet.bullet-secondary {
  background-color: #475F7B;
}

/* For Pagination */
/* -------------- */
.pagination-secondary .page-item.active .page-link, .pagination-secondary .page-item.active .page-link:hover {
  background: #475F7B !important;
  color: #fff;
}

.pagination-secondary .page-item .page-link:hover {
  background-color: #E6EAEE !important;
}

.pagination-secondary .page-item.previous .page-link, .pagination-secondary .page-item.next .page-link {
  color: #475F7B;
}

.pagination-secondary .page-item.previous .page-link:hover, .pagination-secondary .page-item.next .page-link:hover {
  background: #475F7B;
}

/* Progress Bars secondary */
/* ---------------------- */
.progress-bar-secondary .progress-bar {
  background-color: #475F7B;
  box-shadow: 0 2px 6px 0 rgba(71, 95, 123, 0.6);
}

/* Chips secondary */
/* ---------------- */
.chip-secondary {
  background-color: #475F7B !important;
}

.chip-secondary .chip-body {
  color: #fff !important;
}

/* Divider secondary */
/* ---------------*/
.divider.divider-secondary .divider-text:before, .divider.divider-secondary .divider-text:after {
  border-color: #475F7B !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-secondary:before {
  background: #475F7B !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-secondary + .custom-control-label:before, input[type="radio"].bg-secondary + .custom-control-label:before {
  background-color: #475F7B !important;
  border-color: #34465b !important;
}

.checkbox.checkbox-secondary input:checked ~ label::before, .checkbox.radio-secondary input:checked ~ label::before, .radio.checkbox-secondary input:checked ~ label::before, .radio.radio-secondary input:checked ~ label::before {
  background-color: #475F7B;
  border-color: #475F7B;
}

.checkbox.checkbox-secondary input:checked ~ label::after, .checkbox.radio-secondary input:checked ~ label::after, .radio.checkbox-secondary input:checked ~ label::after, .radio.radio-secondary input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-secondary.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-secondary.radio-glow input:checked ~ label::before, .checkbox.radio-secondary.checkbox-glow input:checked ~ label::before, .checkbox.radio-secondary.radio-glow input:checked ~ label::before, .radio.checkbox-secondary.checkbox-glow input:checked ~ label::before, .radio.checkbox-secondary.radio-glow input:checked ~ label::before, .radio.radio-secondary.checkbox-glow input:checked ~ label::before, .radio.radio-secondary.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(71, 95, 123, 0.7);
}

.checkbox.checkbox-secondary.checkbox-icon label i, .checkbox.radio-secondary.checkbox-icon label i, .radio.checkbox-secondary.checkbox-icon label i, .radio.radio-secondary.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-secondary label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-secondary input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-secondary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #475F7B !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-secondary.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(71, 95, 123, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-secondary, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-secondary {
  box-shadow: 0 0 8px 0 rgba(71, 95, 123, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=secondary]:before {
  background-color: #475F7B;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #475F7B;
}

/* Select2 secondary */
/* ---------------*/
select.select-light-secondary ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-secondary ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  background-color: #E6EAEE !important;
  color: #475F7B !important;
}

.secondary.darken-1 {
  color: #3e536b !important;
}

.bg-secondary.bg-darken-1 {
  background-color: #3e536b !important;
}

.btn-secondary.btn-darken-1 {
  border-color: #34465b !important;
  background-color: #3e536b !important;
}

.btn-secondary.btn-darken-1:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-darken-1:focus, .btn-secondary.btn-darken-1:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-darken-1 {
  border-color: #3e536b !important;
  color: #3e536b !important;
}

.btn-outline-secondary.btn-outline-darken-1:hover {
  background-color: #3e536b !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3e536b !important;
}

.border-secondary.border-darken-1 {
  border: 1px solid #3e536b !important;
}

.border-top-secondary.border-top-darken-1 {
  border-top: 1px solid #3e536b !important;
}

.border-bottom-secondary.border-bottom-darken-1 {
  border-bottom: 1px solid #3e536b !important;
}

.border-left-secondary.border-left-darken-1 {
  border-left: 1px solid #3e536b !important;
}

.border-right-secondary.border-right-darken-1 {
  border-right: 1px solid #3e536b !important;
}

.overlay-secondary.overlay-darken-1 {
  background: #3e536b;
  background: rgba(62, 83, 107, 0.6);
}

.secondary.darken-2 {
  color: #34465b !important;
}

.bg-secondary.bg-darken-2 {
  background-color: #34465b !important;
}

.btn-secondary.btn-darken-2 {
  border-color: #34465b !important;
  background-color: #34465b !important;
}

.btn-secondary.btn-darken-2:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-darken-2:focus, .btn-secondary.btn-darken-2:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-darken-2 {
  border-color: #34465b !important;
  color: #34465b !important;
}

.btn-outline-secondary.btn-outline-darken-2:hover {
  background-color: #34465b !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #34465b !important;
}

.border-secondary.border-darken-2 {
  border: 1px solid #34465b !important;
}

.border-top-secondary.border-top-darken-2 {
  border-top: 1px solid #34465b !important;
}

.border-bottom-secondary.border-bottom-darken-2 {
  border-bottom: 1px solid #34465b !important;
}

.border-left-secondary.border-left-darken-2 {
  border-left: 1px solid #34465b !important;
}

.border-right-secondary.border-right-darken-2 {
  border-right: 1px solid #34465b !important;
}

.overlay-secondary.overlay-darken-2 {
  background: #34465b;
  background: rgba(52, 70, 91, 0.6);
}

.secondary.darken-3 {
  color: #2b3a4a !important;
}

.bg-secondary.bg-darken-3 {
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-darken-3 {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-darken-3:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-darken-3:focus, .btn-secondary.btn-darken-3:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-darken-3 {
  border-color: #2b3a4a !important;
  color: #2b3a4a !important;
}

.btn-outline-secondary.btn-outline-darken-3:hover {
  background-color: #2b3a4a !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2b3a4a !important;
}

.border-secondary.border-darken-3 {
  border: 1px solid #2b3a4a !important;
}

.border-top-secondary.border-top-darken-3 {
  border-top: 1px solid #2b3a4a !important;
}

.border-bottom-secondary.border-bottom-darken-3 {
  border-bottom: 1px solid #2b3a4a !important;
}

.border-left-secondary.border-left-darken-3 {
  border-left: 1px solid #2b3a4a !important;
}

.border-right-secondary.border-right-darken-3 {
  border-right: 1px solid #2b3a4a !important;
}

.overlay-secondary.overlay-darken-3 {
  background: #2b3a4a;
  background: rgba(43, 58, 74, 0.6);
}

.secondary.darken-4 {
  color: #222d3a !important;
}

.bg-secondary.bg-darken-4 {
  background-color: #222d3a !important;
}

.btn-secondary.btn-darken-4 {
  border-color: #34465b !important;
  background-color: #222d3a !important;
}

.btn-secondary.btn-darken-4:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-darken-4:focus, .btn-secondary.btn-darken-4:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-darken-4 {
  border-color: #222d3a !important;
  color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-darken-4:hover {
  background-color: #222d3a !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #222d3a !important;
}

.border-secondary.border-darken-4 {
  border: 1px solid #222d3a !important;
}

.border-top-secondary.border-top-darken-4 {
  border-top: 1px solid #222d3a !important;
}

.border-bottom-secondary.border-bottom-darken-4 {
  border-bottom: 1px solid #222d3a !important;
}

.border-left-secondary.border-left-darken-4 {
  border-left: 1px solid #222d3a !important;
}

.border-right-secondary.border-right-darken-4 {
  border-right: 1px solid #222d3a !important;
}

.overlay-secondary.overlay-darken-4 {
  background: #222d3a;
  background: rgba(34, 45, 58, 0.6);
}

.secondary.light {
  color: #E6EAEE !important;
}

.bg-secondary.bg-light {
  background-color: #E6EAEE !important;
}

.btn-secondary.btn-light {
  border-color: #34465b !important;
  background-color: #E6EAEE !important;
}

.btn-secondary.btn-light:hover {
  border-color: #34465b !important;
  background-color: #2b3a4a !important;
}

.btn-secondary.btn-light:focus, .btn-secondary.btn-light:active {
  border-color: #2b3a4a !important;
  background-color: #222d3a !important;
}

.btn-outline-secondary.btn-outline-light {
  border-color: #E6EAEE !important;
  color: #E6EAEE !important;
}

.btn-outline-secondary.btn-outline-light:hover {
  background-color: #E6EAEE !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E6EAEE !important;
}

.border-secondary.border-light {
  border: 1px solid #E6EAEE !important;
}

.border-top-secondary.border-top-light {
  border-top: 1px solid #E6EAEE !important;
}

.border-bottom-secondary.border-bottom-light {
  border-bottom: 1px solid #E6EAEE !important;
}

.border-left-secondary.border-left-light {
  border-left: 1px solid #E6EAEE !important;
}

.border-right-secondary.border-right-light {
  border-right: 1px solid #E6EAEE !important;
}

.overlay-secondary.overlay-light {
  background: #E6EAEE;
  background: rgba(230, 234, 238, 0.6);
}

.success.lighten-5 {
  color: #a4eec9 !important;
}

.bg-success.bg-lighten-5 {
  background-color: #a4eec9 !important;
}

.btn-success.btn-lighten-5 {
  border-color: #23bd70 !important;
  background-color: #a4eec9 !important;
}

.btn-success.btn-lighten-5:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-lighten-5:focus, .btn-success.btn-lighten-5:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-lighten-5 {
  border-color: #a4eec9 !important;
  color: #a4eec9 !important;
}

.btn-outline-success.btn-outline-lighten-5:hover {
  background-color: #a4eec9 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a4eec9 !important;
}

.border-success.border-lighten-5 {
  border: 1px solid #a4eec9 !important;
}

.border-top-success.border-top-lighten-5 {
  border-top: 1px solid #a4eec9 !important;
}

.border-bottom-success.border-bottom-lighten-5 {
  border-bottom: 1px solid #a4eec9 !important;
}

.border-left-success.border-left-lighten-5 {
  border-left: 1px solid #a4eec9 !important;
}

.border-right-success.border-right-lighten-5 {
  border-right: 1px solid #a4eec9 !important;
}

.overlay-success.overlay-lighten-5 {
  background: #a4eec9;
  background: rgba(164, 238, 201, 0.6);
}

.success.lighten-4 {
  color: #8feabd !important;
}

.bg-success.bg-lighten-4 {
  background-color: #8feabd !important;
}

.btn-success.btn-lighten-4 {
  border-color: #23bd70 !important;
  background-color: #8feabd !important;
}

.btn-success.btn-lighten-4:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-lighten-4:focus, .btn-success.btn-lighten-4:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-lighten-4 {
  border-color: #8feabd !important;
  color: #8feabd !important;
}

.btn-outline-success.btn-outline-lighten-4:hover {
  background-color: #8feabd !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8feabd !important;
}

.border-success.border-lighten-4 {
  border: 1px solid #8feabd !important;
}

.border-top-success.border-top-lighten-4 {
  border-top: 1px solid #8feabd !important;
}

.border-bottom-success.border-bottom-lighten-4 {
  border-bottom: 1px solid #8feabd !important;
}

.border-left-success.border-left-lighten-4 {
  border-left: 1px solid #8feabd !important;
}

.border-right-success.border-right-lighten-4 {
  border-right: 1px solid #8feabd !important;
}

.overlay-success.overlay-lighten-4 {
  background: #8feabd;
  background: rgba(143, 234, 189, 0.6);
}

.success.lighten-3 {
  color: #79e6b0 !important;
}

.bg-success.bg-lighten-3 {
  background-color: #79e6b0 !important;
}

.btn-success.btn-lighten-3 {
  border-color: #23bd70 !important;
  background-color: #79e6b0 !important;
}

.btn-success.btn-lighten-3:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-lighten-3:focus, .btn-success.btn-lighten-3:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-lighten-3 {
  border-color: #79e6b0 !important;
  color: #79e6b0 !important;
}

.btn-outline-success.btn-outline-lighten-3:hover {
  background-color: #79e6b0 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #79e6b0 !important;
}

.border-success.border-lighten-3 {
  border: 1px solid #79e6b0 !important;
}

.border-top-success.border-top-lighten-3 {
  border-top: 1px solid #79e6b0 !important;
}

.border-bottom-success.border-bottom-lighten-3 {
  border-bottom: 1px solid #79e6b0 !important;
}

.border-left-success.border-left-lighten-3 {
  border-left: 1px solid #79e6b0 !important;
}

.border-right-success.border-right-lighten-3 {
  border-right: 1px solid #79e6b0 !important;
}

.overlay-success.overlay-lighten-3 {
  background: #79e6b0;
  background: rgba(121, 230, 176, 0.6);
}

.success.lighten-2 {
  color: #64e2a3 !important;
}

.bg-success.bg-lighten-2 {
  background-color: #64e2a3 !important;
}

.btn-success.btn-lighten-2 {
  border-color: #23bd70 !important;
  background-color: #64e2a3 !important;
}

.btn-success.btn-lighten-2:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-lighten-2:focus, .btn-success.btn-lighten-2:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-lighten-2 {
  border-color: #64e2a3 !important;
  color: #64e2a3 !important;
}

.btn-outline-success.btn-outline-lighten-2:hover {
  background-color: #64e2a3 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64e2a3 !important;
}

.border-success.border-lighten-2 {
  border: 1px solid #64e2a3 !important;
}

.border-top-success.border-top-lighten-2 {
  border-top: 1px solid #64e2a3 !important;
}

.border-bottom-success.border-bottom-lighten-2 {
  border-bottom: 1px solid #64e2a3 !important;
}

.border-left-success.border-left-lighten-2 {
  border-left: 1px solid #64e2a3 !important;
}

.border-right-success.border-right-lighten-2 {
  border-right: 1px solid #64e2a3 !important;
}

.overlay-success.overlay-lighten-2 {
  background: #64e2a3;
  background: rgba(100, 226, 163, 0.6);
}

.success.lighten-1 {
  color: #4ede97 !important;
}

.bg-success.bg-lighten-1 {
  background-color: #4ede97 !important;
}

.btn-success.btn-lighten-1 {
  border-color: #23bd70 !important;
  background-color: #4ede97 !important;
}

.btn-success.btn-lighten-1:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-lighten-1:focus, .btn-success.btn-lighten-1:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-lighten-1 {
  border-color: #4ede97 !important;
  color: #4ede97 !important;
}

.btn-outline-success.btn-outline-lighten-1:hover {
  background-color: #4ede97 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4ede97 !important;
}

.border-success.border-lighten-1 {
  border: 1px solid #4ede97 !important;
}

.border-top-success.border-top-lighten-1 {
  border-top: 1px solid #4ede97 !important;
}

.border-bottom-success.border-bottom-lighten-1 {
  border-bottom: 1px solid #4ede97 !important;
}

.border-left-success.border-left-lighten-1 {
  border-left: 1px solid #4ede97 !important;
}

.border-right-success.border-right-lighten-1 {
  border-right: 1px solid #4ede97 !important;
}

.overlay-success.overlay-lighten-1 {
  background: #4ede97;
  background: rgba(78, 222, 151, 0.6);
}

/* success Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.success {
  color: #39DA8A !important;
}

/* text with light success */
.text-light-success {
  color: rgba(57, 218, 138, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-success {
  background-color: #39DA8A !important;
}

.bg-success .card-header,
.bg-success .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-success {
  background: rgba(57, 218, 138, 0.2) !important;
}

.bg-rgba-success.alert {
  color: #39DA8A;
}

.bg-rgba-success.alert.alert-dismissible .close {
  color: #39DA8A;
}

/* Alert success */
/* ---------------- */
.alert.alert-success {
  background: #39DA8A !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(57, 218, 138, 0.4);
  border: none;
}

/* Border success */
/* ---------------- */
.border-success {
  border: 1px solid #39DA8A !important;
}

.border-success .select2-selection__arrow b {
  border-color: #23bd70 !important;
}

.border-success.alert {
  color: #39DA8A;
}

.border-success.alert.alert-dismissible .close {
  color: #39DA8A;
}

.border-top-success {
  border-top: 1px solid #39DA8A;
}

.border-bottom-success {
  border-bottom: 1px solid #39DA8A;
}

.border-left-success {
  border-left: 1px solid #39DA8A;
}

.border-right-success {
  border-right: 1px solid #39DA8A;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-success .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #39DA8A !important;
}

/* Badges */
/* ------ */
.badge.badge-success {
  background-color: #39DA8A;
}

.badge.badge-light-success {
  background-color: #D2FFE8;
  color: #39DA8A !important;
}

.badge.bg-success.badge-glow, .badge.border-success.badge-glow, .badge.badge-success.badge-glow {
  box-shadow: 0px 0px 10px #39DA8A;
}

.badge-circle-success {
  background-color: #39DA8A;
}

.badge-circle-light-success {
  background-color: #D2FFE8;
  color: #39DA8A;
}

.overlay-success {
  background: #39DA8A;
  background: rgba(57, 218, 138, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-success {
  border-color: #23bd70 !important;
  background-color: #39DA8A !important;
  color: #fff;
}

.btn-success:hover, .btn-success.hover {
  background-color: #4ede97 !important;
  color: #fff;
}

.btn-success:hover.glow, .btn-success.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(57, 218, 138, 0.6) !important;
}

.btn-success:focus, .btn-success:active, .btn-success.active {
  background-color: #27d27d !important;
  color: #fff !important;
}

.btn-success.glow {
  box-shadow: 0 2px 4px 0 rgba(57, 218, 138, 0.5) !important;
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff !important;
}

.btn-light-success {
  background-color: #D2FFE8;
  color: #39DA8A !important;
}

.btn-light-success:hover, .btn-light-success.hover {
  background-color: #4ede97 !important;
  color: #fff !important;
}

.btn-light-success:active, .btn-light-success.active {
  background-color: #27d27d !important;
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-success {
  border: 1px solid #39DA8A;
  background-color: transparent;
  color: #39DA8A !important;
}

.btn-outline-success:hover, .btn-outline-success.hover {
  background-color: #4ede97 !important;
  color: #fff !important;
}

.btn-outline-success:active, .btn-outline-success.active {
  background-color: #27d27d !important;
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-success ~ .dropdown-menu .dropdown-item.active, .btn-outline-success ~ .dropdown-menu .dropdown-item.active {
  background-color: #39DA8A;
  color: #fff;
}

.btn-success ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-success ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:hover i {
  color: #39DA8A;
}

.dropdown.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-success ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-success ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet success */
.bullet.bullet-success {
  background-color: #39DA8A;
}

/* For Pagination */
/* -------------- */
.pagination-success .page-item.active .page-link, .pagination-success .page-item.active .page-link:hover {
  background: #39DA8A !important;
  color: #fff;
}

.pagination-success .page-item .page-link:hover {
  background-color: #D2FFE8 !important;
}

.pagination-success .page-item.previous .page-link, .pagination-success .page-item.next .page-link {
  color: #39DA8A;
}

.pagination-success .page-item.previous .page-link:hover, .pagination-success .page-item.next .page-link:hover {
  background: #39DA8A;
}

/* Progress Bars success */
/* ---------------------- */
.progress-bar-success .progress-bar {
  background-color: #39DA8A;
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}

/* Chips success */
/* ---------------- */
.chip-success {
  background-color: #39DA8A !important;
}

.chip-success .chip-body {
  color: #fff !important;
}

/* Divider success */
/* ---------------*/
.divider.divider-success .divider-text:before, .divider.divider-success .divider-text:after {
  border-color: #39DA8A !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-success:before {
  background: #39DA8A !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-success + .custom-control-label:before, input[type="radio"].bg-success + .custom-control-label:before {
  background-color: #39DA8A !important;
  border-color: #23bd70 !important;
}

.checkbox.checkbox-success input:checked ~ label::before, .checkbox.radio-success input:checked ~ label::before, .radio.checkbox-success input:checked ~ label::before, .radio.radio-success input:checked ~ label::before {
  background-color: #39DA8A;
  border-color: #39DA8A;
}

.checkbox.checkbox-success input:checked ~ label::after, .checkbox.radio-success input:checked ~ label::after, .radio.checkbox-success input:checked ~ label::after, .radio.radio-success input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-success.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-success.radio-glow input:checked ~ label::before, .checkbox.radio-success.checkbox-glow input:checked ~ label::before, .checkbox.radio-success.radio-glow input:checked ~ label::before, .radio.checkbox-success.checkbox-glow input:checked ~ label::before, .radio.checkbox-success.radio-glow input:checked ~ label::before, .radio.radio-success.checkbox-glow input:checked ~ label::before, .radio.radio-success.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(57, 218, 138, 0.7);
}

.checkbox.checkbox-success.checkbox-icon label i, .checkbox.radio-success.checkbox-icon label i, .radio.checkbox-success.checkbox-icon label i, .radio.radio-success.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-success label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-success input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-success .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #39DA8A !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-success.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(57, 218, 138, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-success, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-success {
  box-shadow: 0 0 8px 0 rgba(57, 218, 138, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=success]:before {
  background-color: #39DA8A;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #39DA8A;
}

/* Select2 success */
/* ---------------*/
select.select-light-success ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-success ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  background-color: #D2FFE8 !important;
  color: #39DA8A !important;
}

.success.darken-1 {
  color: #27d27d !important;
}

.bg-success.bg-darken-1 {
  background-color: #27d27d !important;
}

.btn-success.btn-darken-1 {
  border-color: #23bd70 !important;
  background-color: #27d27d !important;
}

.btn-success.btn-darken-1:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-darken-1:focus, .btn-success.btn-darken-1:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-darken-1 {
  border-color: #27d27d !important;
  color: #27d27d !important;
}

.btn-outline-success.btn-outline-darken-1:hover {
  background-color: #27d27d !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #27d27d !important;
}

.border-success.border-darken-1 {
  border: 1px solid #27d27d !important;
}

.border-top-success.border-top-darken-1 {
  border-top: 1px solid #27d27d !important;
}

.border-bottom-success.border-bottom-darken-1 {
  border-bottom: 1px solid #27d27d !important;
}

.border-left-success.border-left-darken-1 {
  border-left: 1px solid #27d27d !important;
}

.border-right-success.border-right-darken-1 {
  border-right: 1px solid #27d27d !important;
}

.overlay-success.overlay-darken-1 {
  background: #27d27d;
  background: rgba(39, 210, 125, 0.6);
}

.success.darken-2 {
  color: #23bd70 !important;
}

.bg-success.bg-darken-2 {
  background-color: #23bd70 !important;
}

.btn-success.btn-darken-2 {
  border-color: #23bd70 !important;
  background-color: #23bd70 !important;
}

.btn-success.btn-darken-2:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-darken-2:focus, .btn-success.btn-darken-2:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-darken-2 {
  border-color: #23bd70 !important;
  color: #23bd70 !important;
}

.btn-outline-success.btn-outline-darken-2:hover {
  background-color: #23bd70 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #23bd70 !important;
}

.border-success.border-darken-2 {
  border: 1px solid #23bd70 !important;
}

.border-top-success.border-top-darken-2 {
  border-top: 1px solid #23bd70 !important;
}

.border-bottom-success.border-bottom-darken-2 {
  border-bottom: 1px solid #23bd70 !important;
}

.border-left-success.border-left-darken-2 {
  border-left: 1px solid #23bd70 !important;
}

.border-right-success.border-right-darken-2 {
  border-right: 1px solid #23bd70 !important;
}

.overlay-success.overlay-darken-2 {
  background: #23bd70;
  background: rgba(35, 189, 112, 0.6);
}

.success.darken-3 {
  color: #1fa764 !important;
}

.bg-success.bg-darken-3 {
  background-color: #1fa764 !important;
}

.btn-success.btn-darken-3 {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-darken-3:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-darken-3:focus, .btn-success.btn-darken-3:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-darken-3 {
  border-color: #1fa764 !important;
  color: #1fa764 !important;
}

.btn-outline-success.btn-outline-darken-3:hover {
  background-color: #1fa764 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1fa764 !important;
}

.border-success.border-darken-3 {
  border: 1px solid #1fa764 !important;
}

.border-top-success.border-top-darken-3 {
  border-top: 1px solid #1fa764 !important;
}

.border-bottom-success.border-bottom-darken-3 {
  border-bottom: 1px solid #1fa764 !important;
}

.border-left-success.border-left-darken-3 {
  border-left: 1px solid #1fa764 !important;
}

.border-right-success.border-right-darken-3 {
  border-right: 1px solid #1fa764 !important;
}

.overlay-success.overlay-darken-3 {
  background: #1fa764;
  background: rgba(31, 167, 100, 0.6);
}

.success.darken-4 {
  color: #1b9257 !important;
}

.bg-success.bg-darken-4 {
  background-color: #1b9257 !important;
}

.btn-success.btn-darken-4 {
  border-color: #23bd70 !important;
  background-color: #1b9257 !important;
}

.btn-success.btn-darken-4:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-darken-4:focus, .btn-success.btn-darken-4:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-darken-4 {
  border-color: #1b9257 !important;
  color: #1b9257 !important;
}

.btn-outline-success.btn-outline-darken-4:hover {
  background-color: #1b9257 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1b9257 !important;
}

.border-success.border-darken-4 {
  border: 1px solid #1b9257 !important;
}

.border-top-success.border-top-darken-4 {
  border-top: 1px solid #1b9257 !important;
}

.border-bottom-success.border-bottom-darken-4 {
  border-bottom: 1px solid #1b9257 !important;
}

.border-left-success.border-left-darken-4 {
  border-left: 1px solid #1b9257 !important;
}

.border-right-success.border-right-darken-4 {
  border-right: 1px solid #1b9257 !important;
}

.overlay-success.overlay-darken-4 {
  background: #1b9257;
  background: rgba(27, 146, 87, 0.6);
}

.success.accent-1 {
  color: #E1FFF1 !important;
}

.bg-success.bg-accent-1 {
  background-color: #E1FFF1 !important;
}

.btn-success.btn-accent-1 {
  border-color: #23bd70 !important;
  background-color: #E1FFF1 !important;
}

.btn-success.btn-accent-1:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-accent-1:focus, .btn-success.btn-accent-1:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-accent-1 {
  border-color: #E1FFF1 !important;
  color: #E1FFF1 !important;
}

.btn-outline-success.btn-outline-accent-1:hover {
  background-color: #E1FFF1 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E1FFF1 !important;
}

.border-success.border-accent-1 {
  border: 1px solid #E1FFF1 !important;
}

.border-top-success.border-top-accent-1 {
  border-top: 1px solid #E1FFF1 !important;
}

.border-bottom-success.border-bottom-accent-1 {
  border-bottom: 1px solid #E1FFF1 !important;
}

.border-left-success.border-left-accent-1 {
  border-left: 1px solid #E1FFF1 !important;
}

.border-right-success.border-right-accent-1 {
  border-right: 1px solid #E1FFF1 !important;
}

.overlay-success.overlay-accent-1 {
  background: #E1FFF1;
  background: rgba(225, 255, 241, 0.6);
}

.success.accent-2 {
  color: #AEFFD9 !important;
}

.bg-success.bg-accent-2 {
  background-color: #AEFFD9 !important;
}

.btn-success.btn-accent-2 {
  border-color: #23bd70 !important;
  background-color: #AEFFD9 !important;
}

.btn-success.btn-accent-2:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-accent-2:focus, .btn-success.btn-accent-2:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-accent-2 {
  border-color: #AEFFD9 !important;
  color: #AEFFD9 !important;
}

.btn-outline-success.btn-outline-accent-2:hover {
  background-color: #AEFFD9 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #AEFFD9 !important;
}

.border-success.border-accent-2 {
  border: 1px solid #AEFFD9 !important;
}

.border-top-success.border-top-accent-2 {
  border-top: 1px solid #AEFFD9 !important;
}

.border-bottom-success.border-bottom-accent-2 {
  border-bottom: 1px solid #AEFFD9 !important;
}

.border-left-success.border-left-accent-2 {
  border-left: 1px solid #AEFFD9 !important;
}

.border-right-success.border-right-accent-2 {
  border-right: 1px solid #AEFFD9 !important;
}

.overlay-success.overlay-accent-2 {
  background: #AEFFD9;
  background: rgba(174, 255, 217, 0.6);
}

.success.accent-3 {
  color: #7BFFC1 !important;
}

.bg-success.bg-accent-3 {
  background-color: #7BFFC1 !important;
}

.btn-success.btn-accent-3 {
  border-color: #23bd70 !important;
  background-color: #7BFFC1 !important;
}

.btn-success.btn-accent-3:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-accent-3:focus, .btn-success.btn-accent-3:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-accent-3 {
  border-color: #7BFFC1 !important;
  color: #7BFFC1 !important;
}

.btn-outline-success.btn-outline-accent-3:hover {
  background-color: #7BFFC1 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7BFFC1 !important;
}

.border-success.border-accent-3 {
  border: 1px solid #7BFFC1 !important;
}

.border-top-success.border-top-accent-3 {
  border-top: 1px solid #7BFFC1 !important;
}

.border-bottom-success.border-bottom-accent-3 {
  border-bottom: 1px solid #7BFFC1 !important;
}

.border-left-success.border-left-accent-3 {
  border-left: 1px solid #7BFFC1 !important;
}

.border-right-success.border-right-accent-3 {
  border-right: 1px solid #7BFFC1 !important;
}

.overlay-success.overlay-accent-3 {
  background: #7BFFC1;
  background: rgba(123, 255, 193, 0.6);
}

.success.accent-4 {
  color: #62FFB5 !important;
}

.bg-success.bg-accent-4 {
  background-color: #62FFB5 !important;
}

.btn-success.btn-accent-4 {
  border-color: #23bd70 !important;
  background-color: #62FFB5 !important;
}

.btn-success.btn-accent-4:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-accent-4:focus, .btn-success.btn-accent-4:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-accent-4 {
  border-color: #62FFB5 !important;
  color: #62FFB5 !important;
}

.btn-outline-success.btn-outline-accent-4:hover {
  background-color: #62FFB5 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #62FFB5 !important;
}

.border-success.border-accent-4 {
  border: 1px solid #62FFB5 !important;
}

.border-top-success.border-top-accent-4 {
  border-top: 1px solid #62FFB5 !important;
}

.border-bottom-success.border-bottom-accent-4 {
  border-bottom: 1px solid #62FFB5 !important;
}

.border-left-success.border-left-accent-4 {
  border-left: 1px solid #62FFB5 !important;
}

.border-right-success.border-right-accent-4 {
  border-right: 1px solid #62FFB5 !important;
}

.overlay-success.overlay-accent-4 {
  background: #62FFB5;
  background: rgba(98, 255, 181, 0.6);
}

.success.light {
  color: #D2FFE8 !important;
}

.bg-success.bg-light {
  background-color: #D2FFE8 !important;
}

.btn-success.btn-light {
  border-color: #23bd70 !important;
  background-color: #D2FFE8 !important;
}

.btn-success.btn-light:hover {
  border-color: #23bd70 !important;
  background-color: #1fa764 !important;
}

.btn-success.btn-light:focus, .btn-success.btn-light:active {
  border-color: #1fa764 !important;
  background-color: #1b9257 !important;
}

.btn-outline-success.btn-outline-light {
  border-color: #D2FFE8 !important;
  color: #D2FFE8 !important;
}

.btn-outline-success.btn-outline-light:hover {
  background-color: #D2FFE8 !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #D2FFE8 !important;
}

.border-success.border-light {
  border: 1px solid #D2FFE8 !important;
}

.border-top-success.border-top-light {
  border-top: 1px solid #D2FFE8 !important;
}

.border-bottom-success.border-bottom-light {
  border-bottom: 1px solid #D2FFE8 !important;
}

.border-left-success.border-left-light {
  border-left: 1px solid #D2FFE8 !important;
}

.border-right-success.border-right-light {
  border-right: 1px solid #D2FFE8 !important;
}

.overlay-success.overlay-light {
  background: #D2FFE8;
  background: rgba(210, 255, 232, 0.6);
}

.info.lighten-5 {
  color: #5ef5ff !important;
}

.bg-info.bg-lighten-5 {
  background-color: #5ef5ff !important;
}

.btn-info.btn-lighten-5 {
  border-color: #009faa !important;
  background-color: #5ef5ff !important;
}

.btn-info.btn-lighten-5:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-lighten-5:focus, .btn-info.btn-lighten-5:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-lighten-5 {
  border-color: #5ef5ff !important;
  color: #5ef5ff !important;
}

.btn-outline-info.btn-outline-lighten-5:hover {
  background-color: #5ef5ff !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5ef5ff !important;
}

.border-info.border-lighten-5 {
  border: 1px solid #5ef5ff !important;
}

.border-top-info.border-top-lighten-5 {
  border-top: 1px solid #5ef5ff !important;
}

.border-bottom-info.border-bottom-lighten-5 {
  border-bottom: 1px solid #5ef5ff !important;
}

.border-left-info.border-left-lighten-5 {
  border-left: 1px solid #5ef5ff !important;
}

.border-right-info.border-right-lighten-5 {
  border-right: 1px solid #5ef5ff !important;
}

.overlay-info.overlay-lighten-5 {
  background: #5ef5ff;
  background: rgba(94, 245, 255, 0.6);
}

.info.lighten-4 {
  color: #44f3ff !important;
}

.bg-info.bg-lighten-4 {
  background-color: #44f3ff !important;
}

.btn-info.btn-lighten-4 {
  border-color: #009faa !important;
  background-color: #44f3ff !important;
}

.btn-info.btn-lighten-4:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-lighten-4:focus, .btn-info.btn-lighten-4:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-lighten-4 {
  border-color: #44f3ff !important;
  color: #44f3ff !important;
}

.btn-outline-info.btn-outline-lighten-4:hover {
  background-color: #44f3ff !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #44f3ff !important;
}

.border-info.border-lighten-4 {
  border: 1px solid #44f3ff !important;
}

.border-top-info.border-top-lighten-4 {
  border-top: 1px solid #44f3ff !important;
}

.border-bottom-info.border-bottom-lighten-4 {
  border-bottom: 1px solid #44f3ff !important;
}

.border-left-info.border-left-lighten-4 {
  border-left: 1px solid #44f3ff !important;
}

.border-right-info.border-right-lighten-4 {
  border-right: 1px solid #44f3ff !important;
}

.overlay-info.overlay-lighten-4 {
  background: #44f3ff;
  background: rgba(68, 243, 255, 0.6);
}

.info.lighten-3 {
  color: #2bf2ff !important;
}

.bg-info.bg-lighten-3 {
  background-color: #2bf2ff !important;
}

.btn-info.btn-lighten-3 {
  border-color: #009faa !important;
  background-color: #2bf2ff !important;
}

.btn-info.btn-lighten-3:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-lighten-3:focus, .btn-info.btn-lighten-3:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-lighten-3 {
  border-color: #2bf2ff !important;
  color: #2bf2ff !important;
}

.btn-outline-info.btn-outline-lighten-3:hover {
  background-color: #2bf2ff !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2bf2ff !important;
}

.border-info.border-lighten-3 {
  border: 1px solid #2bf2ff !important;
}

.border-top-info.border-top-lighten-3 {
  border-top: 1px solid #2bf2ff !important;
}

.border-bottom-info.border-bottom-lighten-3 {
  border-bottom: 1px solid #2bf2ff !important;
}

.border-left-info.border-left-lighten-3 {
  border-left: 1px solid #2bf2ff !important;
}

.border-right-info.border-right-lighten-3 {
  border-right: 1px solid #2bf2ff !important;
}

.overlay-info.overlay-lighten-3 {
  background: #2bf2ff;
  background: rgba(43, 242, 255, 0.6);
}

.info.lighten-2 {
  color: #11f0ff !important;
}

.bg-info.bg-lighten-2 {
  background-color: #11f0ff !important;
}

.btn-info.btn-lighten-2 {
  border-color: #009faa !important;
  background-color: #11f0ff !important;
}

.btn-info.btn-lighten-2:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-lighten-2:focus, .btn-info.btn-lighten-2:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-lighten-2 {
  border-color: #11f0ff !important;
  color: #11f0ff !important;
}

.btn-outline-info.btn-outline-lighten-2:hover {
  background-color: #11f0ff !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #11f0ff !important;
}

.border-info.border-lighten-2 {
  border: 1px solid #11f0ff !important;
}

.border-top-info.border-top-lighten-2 {
  border-top: 1px solid #11f0ff !important;
}

.border-bottom-info.border-bottom-lighten-2 {
  border-bottom: 1px solid #11f0ff !important;
}

.border-left-info.border-left-lighten-2 {
  border-left: 1px solid #11f0ff !important;
}

.border-right-info.border-right-lighten-2 {
  border-right: 1px solid #11f0ff !important;
}

.overlay-info.overlay-lighten-2 {
  background: #11f0ff;
  background: rgba(17, 240, 255, 0.6);
}

.info.lighten-1 {
  color: #00e7f7 !important;
}

.bg-info.bg-lighten-1 {
  background-color: #00e7f7 !important;
}

.btn-info.btn-lighten-1 {
  border-color: #009faa !important;
  background-color: #00e7f7 !important;
}

.btn-info.btn-lighten-1:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-lighten-1:focus, .btn-info.btn-lighten-1:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-lighten-1 {
  border-color: #00e7f7 !important;
  color: #00e7f7 !important;
}

.btn-outline-info.btn-outline-lighten-1:hover {
  background-color: #00e7f7 !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00e7f7 !important;
}

.border-info.border-lighten-1 {
  border: 1px solid #00e7f7 !important;
}

.border-top-info.border-top-lighten-1 {
  border-top: 1px solid #00e7f7 !important;
}

.border-bottom-info.border-bottom-lighten-1 {
  border-bottom: 1px solid #00e7f7 !important;
}

.border-left-info.border-left-lighten-1 {
  border-left: 1px solid #00e7f7 !important;
}

.border-right-info.border-right-lighten-1 {
  border-right: 1px solid #00e7f7 !important;
}

.overlay-info.overlay-lighten-1 {
  background: #00e7f7;
  background: rgba(0, 231, 247, 0.6);
}

/* info Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.info {
  color: #00CFDD !important;
}

/* text with light info */
.text-light-info {
  color: rgba(0, 207, 221, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-info {
  background-color: #00CFDD !important;
}

.bg-info .card-header,
.bg-info .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-info {
  background: rgba(0, 207, 221, 0.2) !important;
}

.bg-rgba-info.alert {
  color: #00CFDD;
}

.bg-rgba-info.alert.alert-dismissible .close {
  color: #00CFDD;
}

/* Alert info */
/* ---------------- */
.alert.alert-info {
  background: #00CFDD !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(0, 207, 221, 0.4);
  border: none;
}

/* Border info */
/* ---------------- */
.border-info {
  border: 1px solid #00CFDD !important;
}

.border-info .select2-selection__arrow b {
  border-color: #009faa !important;
}

.border-info.alert {
  color: #00CFDD;
}

.border-info.alert.alert-dismissible .close {
  color: #00CFDD;
}

.border-top-info {
  border-top: 1px solid #00CFDD;
}

.border-bottom-info {
  border-bottom: 1px solid #00CFDD;
}

.border-left-info {
  border-left: 1px solid #00CFDD;
}

.border-right-info {
  border-right: 1px solid #00CFDD;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-info .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #00CFDD !important;
}

/* Badges */
/* ------ */
.badge.badge-info {
  background-color: #00CFDD;
}

.badge.badge-light-info {
  background-color: #CCF5F8;
  color: #00CFDD !important;
}

.badge.bg-info.badge-glow, .badge.border-info.badge-glow, .badge.badge-info.badge-glow {
  box-shadow: 0px 0px 10px #00CFDD;
}

.badge-circle-info {
  background-color: #00CFDD;
}

.badge-circle-light-info {
  background-color: #CCF5F8;
  color: #00CFDD;
}

.overlay-info {
  background: #00CFDD;
  background: rgba(0, 207, 221, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-info {
  border-color: #009faa !important;
  background-color: #00CFDD !important;
  color: #fff;
}

.btn-info:hover, .btn-info.hover {
  background-color: #00e7f7 !important;
  color: #fff;
}

.btn-info:hover.glow, .btn-info.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(0, 207, 221, 0.6) !important;
}

.btn-info:focus, .btn-info:active, .btn-info.active {
  background-color: #00b7c4 !important;
  color: #fff !important;
}

.btn-info.glow {
  box-shadow: 0 2px 4px 0 rgba(0, 207, 221, 0.5) !important;
}

.btn-info:disabled, .btn-info.disabled {
  color: #fff !important;
}

.btn-light-info {
  background-color: #CCF5F8;
  color: #00CFDD !important;
}

.btn-light-info:hover, .btn-light-info.hover {
  background-color: #00e7f7 !important;
  color: #fff !important;
}

.btn-light-info:active, .btn-light-info.active {
  background-color: #00b7c4 !important;
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-info {
  border: 1px solid #00CFDD;
  background-color: transparent;
  color: #00CFDD !important;
}

.btn-outline-info:hover, .btn-outline-info.hover {
  background-color: #00e7f7 !important;
  color: #fff !important;
}

.btn-outline-info:active, .btn-outline-info.active {
  background-color: #00b7c4 !important;
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-info ~ .dropdown-menu .dropdown-item.active, .btn-outline-info ~ .dropdown-menu .dropdown-item.active {
  background-color: #00CFDD;
  color: #fff;
}

.btn-info ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-info ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:hover i {
  color: #00CFDD;
}

.dropdown.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-info ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-info ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet info */
.bullet.bullet-info {
  background-color: #00CFDD;
}

/* For Pagination */
/* -------------- */
.pagination-info .page-item.active .page-link, .pagination-info .page-item.active .page-link:hover {
  background: #00CFDD !important;
  color: #fff;
}

.pagination-info .page-item .page-link:hover {
  background-color: #CCF5F8 !important;
}

.pagination-info .page-item.previous .page-link, .pagination-info .page-item.next .page-link {
  color: #00CFDD;
}

.pagination-info .page-item.previous .page-link:hover, .pagination-info .page-item.next .page-link:hover {
  background: #00CFDD;
}

/* Progress Bars info */
/* ---------------------- */
.progress-bar-info .progress-bar {
  background-color: #00CFDD;
  box-shadow: 0 2px 6px 0 rgba(0, 207, 221, 0.6);
}

/* Chips info */
/* ---------------- */
.chip-info {
  background-color: #00CFDD !important;
}

.chip-info .chip-body {
  color: #fff !important;
}

/* Divider info */
/* ---------------*/
.divider.divider-info .divider-text:before, .divider.divider-info .divider-text:after {
  border-color: #00CFDD !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-info:before {
  background: #00CFDD !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-info + .custom-control-label:before, input[type="radio"].bg-info + .custom-control-label:before {
  background-color: #00CFDD !important;
  border-color: #009faa !important;
}

.checkbox.checkbox-info input:checked ~ label::before, .checkbox.radio-info input:checked ~ label::before, .radio.checkbox-info input:checked ~ label::before, .radio.radio-info input:checked ~ label::before {
  background-color: #00CFDD;
  border-color: #00CFDD;
}

.checkbox.checkbox-info input:checked ~ label::after, .checkbox.radio-info input:checked ~ label::after, .radio.checkbox-info input:checked ~ label::after, .radio.radio-info input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-info.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-info.radio-glow input:checked ~ label::before, .checkbox.radio-info.checkbox-glow input:checked ~ label::before, .checkbox.radio-info.radio-glow input:checked ~ label::before, .radio.checkbox-info.checkbox-glow input:checked ~ label::before, .radio.checkbox-info.radio-glow input:checked ~ label::before, .radio.radio-info.checkbox-glow input:checked ~ label::before, .radio.radio-info.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(0, 207, 221, 0.7);
}

.checkbox.checkbox-info.checkbox-icon label i, .checkbox.radio-info.checkbox-icon label i, .radio.checkbox-info.checkbox-icon label i, .radio.radio-info.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-info label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-info input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-info .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00CFDD !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-info.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(0, 207, 221, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-info, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-info {
  box-shadow: 0 0 8px 0 rgba(0, 207, 221, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=info]:before {
  background-color: #00CFDD;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #00CFDD;
}

/* Select2 info */
/* ---------------*/
select.select-light-info ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-info ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  background-color: #CCF5F8 !important;
  color: #00CFDD !important;
}

.info.darken-1 {
  color: #00b7c4 !important;
}

.bg-info.bg-darken-1 {
  background-color: #00b7c4 !important;
}

.btn-info.btn-darken-1 {
  border-color: #009faa !important;
  background-color: #00b7c4 !important;
}

.btn-info.btn-darken-1:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-darken-1:focus, .btn-info.btn-darken-1:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-darken-1 {
  border-color: #00b7c4 !important;
  color: #00b7c4 !important;
}

.btn-outline-info.btn-outline-darken-1:hover {
  background-color: #00b7c4 !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00b7c4 !important;
}

.border-info.border-darken-1 {
  border: 1px solid #00b7c4 !important;
}

.border-top-info.border-top-darken-1 {
  border-top: 1px solid #00b7c4 !important;
}

.border-bottom-info.border-bottom-darken-1 {
  border-bottom: 1px solid #00b7c4 !important;
}

.border-left-info.border-left-darken-1 {
  border-left: 1px solid #00b7c4 !important;
}

.border-right-info.border-right-darken-1 {
  border-right: 1px solid #00b7c4 !important;
}

.overlay-info.overlay-darken-1 {
  background: #00b7c4;
  background: rgba(0, 183, 196, 0.6);
}

.info.darken-2 {
  color: #009faa !important;
}

.bg-info.bg-darken-2 {
  background-color: #009faa !important;
}

.btn-info.btn-darken-2 {
  border-color: #009faa !important;
  background-color: #009faa !important;
}

.btn-info.btn-darken-2:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-darken-2:focus, .btn-info.btn-darken-2:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-darken-2 {
  border-color: #009faa !important;
  color: #009faa !important;
}

.btn-outline-info.btn-outline-darken-2:hover {
  background-color: #009faa !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009faa !important;
}

.border-info.border-darken-2 {
  border: 1px solid #009faa !important;
}

.border-top-info.border-top-darken-2 {
  border-top: 1px solid #009faa !important;
}

.border-bottom-info.border-bottom-darken-2 {
  border-bottom: 1px solid #009faa !important;
}

.border-left-info.border-left-darken-2 {
  border-left: 1px solid #009faa !important;
}

.border-right-info.border-right-darken-2 {
  border-right: 1px solid #009faa !important;
}

.overlay-info.overlay-darken-2 {
  background: #009faa;
  background: rgba(0, 159, 170, 0.6);
}

.info.darken-3 {
  color: #008791 !important;
}

.bg-info.bg-darken-3 {
  background-color: #008791 !important;
}

.btn-info.btn-darken-3 {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-darken-3:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-darken-3:focus, .btn-info.btn-darken-3:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-darken-3 {
  border-color: #008791 !important;
  color: #008791 !important;
}

.btn-outline-info.btn-outline-darken-3:hover {
  background-color: #008791 !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008791 !important;
}

.border-info.border-darken-3 {
  border: 1px solid #008791 !important;
}

.border-top-info.border-top-darken-3 {
  border-top: 1px solid #008791 !important;
}

.border-bottom-info.border-bottom-darken-3 {
  border-bottom: 1px solid #008791 !important;
}

.border-left-info.border-left-darken-3 {
  border-left: 1px solid #008791 !important;
}

.border-right-info.border-right-darken-3 {
  border-right: 1px solid #008791 !important;
}

.overlay-info.overlay-darken-3 {
  background: #008791;
  background: rgba(0, 135, 145, 0.6);
}

.info.darken-4 {
  color: #006f77 !important;
}

.bg-info.bg-darken-4 {
  background-color: #006f77 !important;
}

.btn-info.btn-darken-4 {
  border-color: #009faa !important;
  background-color: #006f77 !important;
}

.btn-info.btn-darken-4:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-darken-4:focus, .btn-info.btn-darken-4:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-darken-4 {
  border-color: #006f77 !important;
  color: #006f77 !important;
}

.btn-outline-info.btn-outline-darken-4:hover {
  background-color: #006f77 !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006f77 !important;
}

.border-info.border-darken-4 {
  border: 1px solid #006f77 !important;
}

.border-top-info.border-top-darken-4 {
  border-top: 1px solid #006f77 !important;
}

.border-bottom-info.border-bottom-darken-4 {
  border-bottom: 1px solid #006f77 !important;
}

.border-left-info.border-left-darken-4 {
  border-left: 1px solid #006f77 !important;
}

.border-right-info.border-right-darken-4 {
  border-right: 1px solid #006f77 !important;
}

.overlay-info.overlay-darken-4 {
  background: #006f77;
  background: rgba(0, 111, 119, 0.6);
}

.info.accent-1 {
  color: #FEFFFF !important;
}

.bg-info.bg-accent-1 {
  background-color: #FEFFFF !important;
}

.btn-info.btn-accent-1 {
  border-color: #009faa !important;
  background-color: #FEFFFF !important;
}

.btn-info.btn-accent-1:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-accent-1:focus, .btn-info.btn-accent-1:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-accent-1 {
  border-color: #FEFFFF !important;
  color: #FEFFFF !important;
}

.btn-outline-info.btn-outline-accent-1:hover {
  background-color: #FEFFFF !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FEFFFF !important;
}

.border-info.border-accent-1 {
  border: 1px solid #FEFFFF !important;
}

.border-top-info.border-top-accent-1 {
  border-top: 1px solid #FEFFFF !important;
}

.border-bottom-info.border-bottom-accent-1 {
  border-bottom: 1px solid #FEFFFF !important;
}

.border-left-info.border-left-accent-1 {
  border-left: 1px solid #FEFFFF !important;
}

.border-right-info.border-right-accent-1 {
  border-right: 1px solid #FEFFFF !important;
}

.overlay-info.overlay-accent-1 {
  background: #FEFFFF;
  background: rgba(254, 255, 255, 0.6);
}

.info.accent-2 {
  color: #CBF5FF !important;
}

.bg-info.bg-accent-2 {
  background-color: #CBF5FF !important;
}

.btn-info.btn-accent-2 {
  border-color: #009faa !important;
  background-color: #CBF5FF !important;
}

.btn-info.btn-accent-2:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-accent-2:focus, .btn-info.btn-accent-2:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-accent-2 {
  border-color: #CBF5FF !important;
  color: #CBF5FF !important;
}

.btn-outline-info.btn-outline-accent-2:hover {
  background-color: #CBF5FF !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CBF5FF !important;
}

.border-info.border-accent-2 {
  border: 1px solid #CBF5FF !important;
}

.border-top-info.border-top-accent-2 {
  border-top: 1px solid #CBF5FF !important;
}

.border-bottom-info.border-bottom-accent-2 {
  border-bottom: 1px solid #CBF5FF !important;
}

.border-left-info.border-left-accent-2 {
  border-left: 1px solid #CBF5FF !important;
}

.border-right-info.border-right-accent-2 {
  border-right: 1px solid #CBF5FF !important;
}

.overlay-info.overlay-accent-2 {
  background: #CBF5FF;
  background: rgba(203, 245, 255, 0.6);
}

.info.accent-3 {
  color: #98ECFF !important;
}

.bg-info.bg-accent-3 {
  background-color: #98ECFF !important;
}

.btn-info.btn-accent-3 {
  border-color: #009faa !important;
  background-color: #98ECFF !important;
}

.btn-info.btn-accent-3:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-accent-3:focus, .btn-info.btn-accent-3:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-accent-3 {
  border-color: #98ECFF !important;
  color: #98ECFF !important;
}

.btn-outline-info.btn-outline-accent-3:hover {
  background-color: #98ECFF !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #98ECFF !important;
}

.border-info.border-accent-3 {
  border: 1px solid #98ECFF !important;
}

.border-top-info.border-top-accent-3 {
  border-top: 1px solid #98ECFF !important;
}

.border-bottom-info.border-bottom-accent-3 {
  border-bottom: 1px solid #98ECFF !important;
}

.border-left-info.border-left-accent-3 {
  border-left: 1px solid #98ECFF !important;
}

.border-right-info.border-right-accent-3 {
  border-right: 1px solid #98ECFF !important;
}

.overlay-info.overlay-accent-3 {
  background: #98ECFF;
  background: rgba(152, 236, 255, 0.6);
}

.info.accent-4 {
  color: #7FE7FF !important;
}

.bg-info.bg-accent-4 {
  background-color: #7FE7FF !important;
}

.btn-info.btn-accent-4 {
  border-color: #009faa !important;
  background-color: #7FE7FF !important;
}

.btn-info.btn-accent-4:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-accent-4:focus, .btn-info.btn-accent-4:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-accent-4 {
  border-color: #7FE7FF !important;
  color: #7FE7FF !important;
}

.btn-outline-info.btn-outline-accent-4:hover {
  background-color: #7FE7FF !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7FE7FF !important;
}

.border-info.border-accent-4 {
  border: 1px solid #7FE7FF !important;
}

.border-top-info.border-top-accent-4 {
  border-top: 1px solid #7FE7FF !important;
}

.border-bottom-info.border-bottom-accent-4 {
  border-bottom: 1px solid #7FE7FF !important;
}

.border-left-info.border-left-accent-4 {
  border-left: 1px solid #7FE7FF !important;
}

.border-right-info.border-right-accent-4 {
  border-right: 1px solid #7FE7FF !important;
}

.overlay-info.overlay-accent-4 {
  background: #7FE7FF;
  background: rgba(127, 231, 255, 0.6);
}

.info.light {
  color: #CCF5F8 !important;
}

.bg-info.bg-light {
  background-color: #CCF5F8 !important;
}

.btn-info.btn-light {
  border-color: #009faa !important;
  background-color: #CCF5F8 !important;
}

.btn-info.btn-light:hover {
  border-color: #009faa !important;
  background-color: #008791 !important;
}

.btn-info.btn-light:focus, .btn-info.btn-light:active {
  border-color: #008791 !important;
  background-color: #006f77 !important;
}

.btn-outline-info.btn-outline-light {
  border-color: #CCF5F8 !important;
  color: #CCF5F8 !important;
}

.btn-outline-info.btn-outline-light:hover {
  background-color: #CCF5F8 !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #CCF5F8 !important;
}

.border-info.border-light {
  border: 1px solid #CCF5F8 !important;
}

.border-top-info.border-top-light {
  border-top: 1px solid #CCF5F8 !important;
}

.border-bottom-info.border-bottom-light {
  border-bottom: 1px solid #CCF5F8 !important;
}

.border-left-info.border-left-light {
  border-left: 1px solid #CCF5F8 !important;
}

.border-right-info.border-right-light {
  border-right: 1px solid #CCF5F8 !important;
}

.overlay-info.overlay-light {
  background: #CCF5F8;
  background: rgba(204, 245, 248, 0.6);
}

.warning.lighten-5 {
  color: #fee3bf !important;
}

.bg-warning.bg-lighten-5 {
  background-color: #fee3bf !important;
}

.btn-warning.btn-lighten-5 {
  border-color: #fc960f !important;
  background-color: #fee3bf !important;
}

.btn-warning.btn-lighten-5:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-lighten-5:focus, .btn-warning.btn-lighten-5:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-lighten-5 {
  border-color: #fee3bf !important;
  color: #fee3bf !important;
}

.btn-outline-warning.btn-outline-lighten-5:hover {
  background-color: #fee3bf !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fee3bf !important;
}

.border-warning.border-lighten-5 {
  border: 1px solid #fee3bf !important;
}

.border-top-warning.border-top-lighten-5 {
  border-top: 1px solid #fee3bf !important;
}

.border-bottom-warning.border-bottom-lighten-5 {
  border-bottom: 1px solid #fee3bf !important;
}

.border-left-warning.border-left-lighten-5 {
  border-left: 1px solid #fee3bf !important;
}

.border-right-warning.border-right-lighten-5 {
  border-right: 1px solid #fee3bf !important;
}

.overlay-warning.overlay-lighten-5 {
  background: #fee3bf;
  background: rgba(254, 227, 191, 0.6);
}

.warning.lighten-4 {
  color: #fed8a6 !important;
}

.bg-warning.bg-lighten-4 {
  background-color: #fed8a6 !important;
}

.btn-warning.btn-lighten-4 {
  border-color: #fc960f !important;
  background-color: #fed8a6 !important;
}

.btn-warning.btn-lighten-4:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-lighten-4:focus, .btn-warning.btn-lighten-4:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-lighten-4 {
  border-color: #fed8a6 !important;
  color: #fed8a6 !important;
}

.btn-outline-warning.btn-outline-lighten-4:hover {
  background-color: #fed8a6 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fed8a6 !important;
}

.border-warning.border-lighten-4 {
  border: 1px solid #fed8a6 !important;
}

.border-top-warning.border-top-lighten-4 {
  border-top: 1px solid #fed8a6 !important;
}

.border-bottom-warning.border-bottom-lighten-4 {
  border-bottom: 1px solid #fed8a6 !important;
}

.border-left-warning.border-left-lighten-4 {
  border-left: 1px solid #fed8a6 !important;
}

.border-right-warning.border-right-lighten-4 {
  border-right: 1px solid #fed8a6 !important;
}

.overlay-warning.overlay-lighten-4 {
  background: #fed8a6;
  background: rgba(254, 216, 166, 0.6);
}

.warning.lighten-3 {
  color: #fecd8d !important;
}

.bg-warning.bg-lighten-3 {
  background-color: #fecd8d !important;
}

.btn-warning.btn-lighten-3 {
  border-color: #fc960f !important;
  background-color: #fecd8d !important;
}

.btn-warning.btn-lighten-3:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-lighten-3:focus, .btn-warning.btn-lighten-3:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-lighten-3 {
  border-color: #fecd8d !important;
  color: #fecd8d !important;
}

.btn-outline-warning.btn-outline-lighten-3:hover {
  background-color: #fecd8d !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fecd8d !important;
}

.border-warning.border-lighten-3 {
  border: 1px solid #fecd8d !important;
}

.border-top-warning.border-top-lighten-3 {
  border-top: 1px solid #fecd8d !important;
}

.border-bottom-warning.border-bottom-lighten-3 {
  border-bottom: 1px solid #fecd8d !important;
}

.border-left-warning.border-left-lighten-3 {
  border-left: 1px solid #fecd8d !important;
}

.border-right-warning.border-right-lighten-3 {
  border-right: 1px solid #fecd8d !important;
}

.overlay-warning.overlay-lighten-3 {
  background: #fecd8d;
  background: rgba(254, 205, 141, 0.6);
}

.warning.lighten-2 {
  color: #fec273 !important;
}

.bg-warning.bg-lighten-2 {
  background-color: #fec273 !important;
}

.btn-warning.btn-lighten-2 {
  border-color: #fc960f !important;
  background-color: #fec273 !important;
}

.btn-warning.btn-lighten-2:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-lighten-2:focus, .btn-warning.btn-lighten-2:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-lighten-2 {
  border-color: #fec273 !important;
  color: #fec273 !important;
}

.btn-outline-warning.btn-outline-lighten-2:hover {
  background-color: #fec273 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fec273 !important;
}

.border-warning.border-lighten-2 {
  border: 1px solid #fec273 !important;
}

.border-top-warning.border-top-lighten-2 {
  border-top: 1px solid #fec273 !important;
}

.border-bottom-warning.border-bottom-lighten-2 {
  border-bottom: 1px solid #fec273 !important;
}

.border-left-warning.border-left-lighten-2 {
  border-left: 1px solid #fec273 !important;
}

.border-right-warning.border-right-lighten-2 {
  border-right: 1px solid #fec273 !important;
}

.overlay-warning.overlay-lighten-2 {
  background: #fec273;
  background: rgba(254, 194, 115, 0.6);
}

.warning.lighten-1 {
  color: #fdb75a !important;
}

.bg-warning.bg-lighten-1 {
  background-color: #fdb75a !important;
}

.btn-warning.btn-lighten-1 {
  border-color: #fc960f !important;
  background-color: #fdb75a !important;
}

.btn-warning.btn-lighten-1:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-lighten-1:focus, .btn-warning.btn-lighten-1:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-lighten-1 {
  border-color: #fdb75a !important;
  color: #fdb75a !important;
}

.btn-outline-warning.btn-outline-lighten-1:hover {
  background-color: #fdb75a !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fdb75a !important;
}

.border-warning.border-lighten-1 {
  border: 1px solid #fdb75a !important;
}

.border-top-warning.border-top-lighten-1 {
  border-top: 1px solid #fdb75a !important;
}

.border-bottom-warning.border-bottom-lighten-1 {
  border-bottom: 1px solid #fdb75a !important;
}

.border-left-warning.border-left-lighten-1 {
  border-left: 1px solid #fdb75a !important;
}

.border-right-warning.border-right-lighten-1 {
  border-right: 1px solid #fdb75a !important;
}

.overlay-warning.overlay-lighten-1 {
  background: #fdb75a;
  background: rgba(253, 183, 90, 0.6);
}

/* warning Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.warning {
  color: #FDAC41 !important;
}

/* text with light warning */
.text-light-warning {
  color: rgba(253, 172, 65, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-warning {
  background-color: #FDAC41 !important;
}

.bg-warning .card-header,
.bg-warning .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-warning {
  background: rgba(253, 172, 65, 0.2) !important;
}

.bg-rgba-warning.alert {
  color: #FDAC41;
}

.bg-rgba-warning.alert.alert-dismissible .close {
  color: #FDAC41;
}

/* Alert warning */
/* ---------------- */
.alert.alert-warning {
  background: #FDAC41 !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(253, 172, 65, 0.4);
  border: none;
}

/* Border warning */
/* ---------------- */
.border-warning {
  border: 1px solid #FDAC41 !important;
}

.border-warning .select2-selection__arrow b {
  border-color: #fc960f !important;
}

.border-warning.alert {
  color: #FDAC41;
}

.border-warning.alert.alert-dismissible .close {
  color: #FDAC41;
}

.border-top-warning {
  border-top: 1px solid #FDAC41;
}

.border-bottom-warning {
  border-bottom: 1px solid #FDAC41;
}

.border-left-warning {
  border-left: 1px solid #FDAC41;
}

.border-right-warning {
  border-right: 1px solid #FDAC41;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-warning .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #FDAC41 !important;
}

/* Badges */
/* ------ */
.badge.badge-warning {
  background-color: #FDAC41;
}

.badge.badge-light-warning {
  background-color: #FFEED9;
  color: #FDAC41 !important;
}

.badge.bg-warning.badge-glow, .badge.border-warning.badge-glow, .badge.badge-warning.badge-glow {
  box-shadow: 0px 0px 10px #FDAC41;
}

.badge-circle-warning {
  background-color: #FDAC41;
}

.badge-circle-light-warning {
  background-color: #FFEED9;
  color: #FDAC41;
}

.overlay-warning {
  background: #FDAC41;
  background: rgba(253, 172, 65, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-warning {
  border-color: #fc960f !important;
  background-color: #FDAC41 !important;
  color: #fff;
}

.btn-warning:hover, .btn-warning.hover {
  background-color: #fdb75a !important;
  color: #fff;
}

.btn-warning:hover.glow, .btn-warning.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(253, 172, 65, 0.6) !important;
}

.btn-warning:focus, .btn-warning:active, .btn-warning.active {
  background-color: #fda128 !important;
  color: #fff !important;
}

.btn-warning.glow {
  box-shadow: 0 2px 4px 0 rgba(253, 172, 65, 0.5) !important;
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #fff !important;
}

.btn-light-warning {
  background-color: #FFEED9;
  color: #FDAC41 !important;
}

.btn-light-warning:hover, .btn-light-warning.hover {
  background-color: #fdb75a !important;
  color: #fff !important;
}

.btn-light-warning:active, .btn-light-warning.active {
  background-color: #fda128 !important;
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-warning {
  border: 1px solid #FDAC41;
  background-color: transparent;
  color: #FDAC41 !important;
}

.btn-outline-warning:hover, .btn-outline-warning.hover {
  background-color: #fdb75a !important;
  color: #fff !important;
}

.btn-outline-warning:active, .btn-outline-warning.active {
  background-color: #fda128 !important;
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-warning ~ .dropdown-menu .dropdown-item.active, .btn-outline-warning ~ .dropdown-menu .dropdown-item.active {
  background-color: #FDAC41;
  color: #fff;
}

.btn-warning ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-warning ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:hover i {
  color: #FDAC41;
}

.dropdown.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-warning ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-warning ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet warning */
.bullet.bullet-warning {
  background-color: #FDAC41;
}

/* For Pagination */
/* -------------- */
.pagination-warning .page-item.active .page-link, .pagination-warning .page-item.active .page-link:hover {
  background: #FDAC41 !important;
  color: #fff;
}

.pagination-warning .page-item .page-link:hover {
  background-color: #FFEED9 !important;
}

.pagination-warning .page-item.previous .page-link, .pagination-warning .page-item.next .page-link {
  color: #FDAC41;
}

.pagination-warning .page-item.previous .page-link:hover, .pagination-warning .page-item.next .page-link:hover {
  background: #FDAC41;
}

/* Progress Bars warning */
/* ---------------------- */
.progress-bar-warning .progress-bar {
  background-color: #FDAC41;
  box-shadow: 0 2px 6px 0 rgba(253, 172, 65, 0.6);
}

/* Chips warning */
/* ---------------- */
.chip-warning {
  background-color: #FDAC41 !important;
}

.chip-warning .chip-body {
  color: #fff !important;
}

/* Divider warning */
/* ---------------*/
.divider.divider-warning .divider-text:before, .divider.divider-warning .divider-text:after {
  border-color: #FDAC41 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-warning:before {
  background: #FDAC41 !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-warning + .custom-control-label:before, input[type="radio"].bg-warning + .custom-control-label:before {
  background-color: #FDAC41 !important;
  border-color: #fc960f !important;
}

.checkbox.checkbox-warning input:checked ~ label::before, .checkbox.radio-warning input:checked ~ label::before, .radio.checkbox-warning input:checked ~ label::before, .radio.radio-warning input:checked ~ label::before {
  background-color: #FDAC41;
  border-color: #FDAC41;
}

.checkbox.checkbox-warning input:checked ~ label::after, .checkbox.radio-warning input:checked ~ label::after, .radio.checkbox-warning input:checked ~ label::after, .radio.radio-warning input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-warning.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-warning.radio-glow input:checked ~ label::before, .checkbox.radio-warning.checkbox-glow input:checked ~ label::before, .checkbox.radio-warning.radio-glow input:checked ~ label::before, .radio.checkbox-warning.checkbox-glow input:checked ~ label::before, .radio.checkbox-warning.radio-glow input:checked ~ label::before, .radio.radio-warning.checkbox-glow input:checked ~ label::before, .radio.radio-warning.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(253, 172, 65, 0.7);
}

.checkbox.checkbox-warning.checkbox-icon label i, .checkbox.radio-warning.checkbox-icon label i, .radio.checkbox-warning.checkbox-icon label i, .radio.radio-warning.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-warning label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-warning input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-warning .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FDAC41 !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-warning.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(253, 172, 65, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-warning, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-warning {
  box-shadow: 0 0 8px 0 rgba(253, 172, 65, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=warning]:before {
  background-color: #FDAC41;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #FDAC41;
}

/* Select2 warning */
/* ---------------*/
select.select-light-warning ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-warning ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  background-color: #FFEED9 !important;
  color: #FDAC41 !important;
}

.warning.darken-1 {
  color: #fda128 !important;
}

.bg-warning.bg-darken-1 {
  background-color: #fda128 !important;
}

.btn-warning.btn-darken-1 {
  border-color: #fc960f !important;
  background-color: #fda128 !important;
}

.btn-warning.btn-darken-1:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-darken-1:focus, .btn-warning.btn-darken-1:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-darken-1 {
  border-color: #fda128 !important;
  color: #fda128 !important;
}

.btn-outline-warning.btn-outline-darken-1:hover {
  background-color: #fda128 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fda128 !important;
}

.border-warning.border-darken-1 {
  border: 1px solid #fda128 !important;
}

.border-top-warning.border-top-darken-1 {
  border-top: 1px solid #fda128 !important;
}

.border-bottom-warning.border-bottom-darken-1 {
  border-bottom: 1px solid #fda128 !important;
}

.border-left-warning.border-left-darken-1 {
  border-left: 1px solid #fda128 !important;
}

.border-right-warning.border-right-darken-1 {
  border-right: 1px solid #fda128 !important;
}

.overlay-warning.overlay-darken-1 {
  background: #fda128;
  background: rgba(253, 161, 40, 0.6);
}

.warning.darken-2 {
  color: #fc960f !important;
}

.bg-warning.bg-darken-2 {
  background-color: #fc960f !important;
}

.btn-warning.btn-darken-2 {
  border-color: #fc960f !important;
  background-color: #fc960f !important;
}

.btn-warning.btn-darken-2:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-darken-2:focus, .btn-warning.btn-darken-2:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-darken-2 {
  border-color: #fc960f !important;
  color: #fc960f !important;
}

.btn-outline-warning.btn-outline-darken-2:hover {
  background-color: #fc960f !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fc960f !important;
}

.border-warning.border-darken-2 {
  border: 1px solid #fc960f !important;
}

.border-top-warning.border-top-darken-2 {
  border-top: 1px solid #fc960f !important;
}

.border-bottom-warning.border-bottom-darken-2 {
  border-bottom: 1px solid #fc960f !important;
}

.border-left-warning.border-left-darken-2 {
  border-left: 1px solid #fc960f !important;
}

.border-right-warning.border-right-darken-2 {
  border-right: 1px solid #fc960f !important;
}

.overlay-warning.overlay-darken-2 {
  background: #fc960f;
  background: rgba(252, 150, 15, 0.6);
}

.warning.darken-3 {
  color: #ef8903 !important;
}

.bg-warning.bg-darken-3 {
  background-color: #ef8903 !important;
}

.btn-warning.btn-darken-3 {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-darken-3:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-darken-3:focus, .btn-warning.btn-darken-3:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-darken-3 {
  border-color: #ef8903 !important;
  color: #ef8903 !important;
}

.btn-outline-warning.btn-outline-darken-3:hover {
  background-color: #ef8903 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef8903 !important;
}

.border-warning.border-darken-3 {
  border: 1px solid #ef8903 !important;
}

.border-top-warning.border-top-darken-3 {
  border-top: 1px solid #ef8903 !important;
}

.border-bottom-warning.border-bottom-darken-3 {
  border-bottom: 1px solid #ef8903 !important;
}

.border-left-warning.border-left-darken-3 {
  border-left: 1px solid #ef8903 !important;
}

.border-right-warning.border-right-darken-3 {
  border-right: 1px solid #ef8903 !important;
}

.overlay-warning.overlay-darken-3 {
  background: #ef8903;
  background: rgba(239, 137, 3, 0.6);
}

.warning.darken-4 {
  color: #d67b02 !important;
}

.bg-warning.bg-darken-4 {
  background-color: #d67b02 !important;
}

.btn-warning.btn-darken-4 {
  border-color: #fc960f !important;
  background-color: #d67b02 !important;
}

.btn-warning.btn-darken-4:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-darken-4:focus, .btn-warning.btn-darken-4:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-darken-4 {
  border-color: #d67b02 !important;
  color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-darken-4:hover {
  background-color: #d67b02 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d67b02 !important;
}

.border-warning.border-darken-4 {
  border: 1px solid #d67b02 !important;
}

.border-top-warning.border-top-darken-4 {
  border-top: 1px solid #d67b02 !important;
}

.border-bottom-warning.border-bottom-darken-4 {
  border-bottom: 1px solid #d67b02 !important;
}

.border-left-warning.border-left-darken-4 {
  border-left: 1px solid #d67b02 !important;
}

.border-right-warning.border-right-darken-4 {
  border-right: 1px solid #d67b02 !important;
}

.overlay-warning.overlay-darken-4 {
  background: #d67b02;
  background: rgba(214, 123, 2, 0.6);
}

.warning.accent-1 {
  color: #FFF5EF !important;
}

.bg-warning.bg-accent-1 {
  background-color: #FFF5EF !important;
}

.btn-warning.btn-accent-1 {
  border-color: #fc960f !important;
  background-color: #FFF5EF !important;
}

.btn-warning.btn-accent-1:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-accent-1:focus, .btn-warning.btn-accent-1:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-accent-1 {
  border-color: #FFF5EF !important;
  color: #FFF5EF !important;
}

.btn-outline-warning.btn-outline-accent-1:hover {
  background-color: #FFF5EF !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF5EF !important;
}

.border-warning.border-accent-1 {
  border: 1px solid #FFF5EF !important;
}

.border-top-warning.border-top-accent-1 {
  border-top: 1px solid #FFF5EF !important;
}

.border-bottom-warning.border-bottom-accent-1 {
  border-bottom: 1px solid #FFF5EF !important;
}

.border-left-warning.border-left-accent-1 {
  border-left: 1px solid #FFF5EF !important;
}

.border-right-warning.border-right-accent-1 {
  border-right: 1px solid #FFF5EF !important;
}

.overlay-warning.overlay-accent-1 {
  background: #FFF5EF;
  background: rgba(255, 245, 239, 0.6);
}

.warning.accent-2 {
  color: #FFE5D8 !important;
}

.bg-warning.bg-accent-2 {
  background-color: #FFE5D8 !important;
}

.btn-warning.btn-accent-2 {
  border-color: #fc960f !important;
  background-color: #FFE5D8 !important;
}

.btn-warning.btn-accent-2:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-accent-2:focus, .btn-warning.btn-accent-2:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-accent-2 {
  border-color: #FFE5D8 !important;
  color: #FFE5D8 !important;
}

.btn-outline-warning.btn-outline-accent-2:hover {
  background-color: #FFE5D8 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE5D8 !important;
}

.border-warning.border-accent-2 {
  border: 1px solid #FFE5D8 !important;
}

.border-top-warning.border-top-accent-2 {
  border-top: 1px solid #FFE5D8 !important;
}

.border-bottom-warning.border-bottom-accent-2 {
  border-bottom: 1px solid #FFE5D8 !important;
}

.border-left-warning.border-left-accent-2 {
  border-left: 1px solid #FFE5D8 !important;
}

.border-right-warning.border-right-accent-2 {
  border-right: 1px solid #FFE5D8 !important;
}

.overlay-warning.overlay-accent-2 {
  background: #FFE5D8;
  background: rgba(255, 229, 216, 0.6);
}

.warning.accent-3 {
  color: #FFF6F3 !important;
}

.bg-warning.bg-accent-3 {
  background-color: #FFF6F3 !important;
}

.btn-warning.btn-accent-3 {
  border-color: #fc960f !important;
  background-color: #FFF6F3 !important;
}

.btn-warning.btn-accent-3:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-accent-3:focus, .btn-warning.btn-accent-3:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-accent-3 {
  border-color: #FFF6F3 !important;
  color: #FFF6F3 !important;
}

.btn-outline-warning.btn-outline-accent-3:hover {
  background-color: #FFF6F3 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF6F3 !important;
}

.border-warning.border-accent-3 {
  border: 1px solid #FFF6F3 !important;
}

.border-top-warning.border-top-accent-3 {
  border-top: 1px solid #FFF6F3 !important;
}

.border-bottom-warning.border-bottom-accent-3 {
  border-bottom: 1px solid #FFF6F3 !important;
}

.border-left-warning.border-left-accent-3 {
  border-left: 1px solid #FFF6F3 !important;
}

.border-right-warning.border-right-accent-3 {
  border-right: 1px solid #FFF6F3 !important;
}

.overlay-warning.overlay-accent-3 {
  background: #FFF6F3;
  background: rgba(255, 246, 243, 0.6);
}

.warning.accent-4 {
  color: #FFE3DA !important;
}

.bg-warning.bg-accent-4 {
  background-color: #FFE3DA !important;
}

.btn-warning.btn-accent-4 {
  border-color: #fc960f !important;
  background-color: #FFE3DA !important;
}

.btn-warning.btn-accent-4:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-accent-4:focus, .btn-warning.btn-accent-4:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-accent-4 {
  border-color: #FFE3DA !important;
  color: #FFE3DA !important;
}

.btn-outline-warning.btn-outline-accent-4:hover {
  background-color: #FFE3DA !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE3DA !important;
}

.border-warning.border-accent-4 {
  border: 1px solid #FFE3DA !important;
}

.border-top-warning.border-top-accent-4 {
  border-top: 1px solid #FFE3DA !important;
}

.border-bottom-warning.border-bottom-accent-4 {
  border-bottom: 1px solid #FFE3DA !important;
}

.border-left-warning.border-left-accent-4 {
  border-left: 1px solid #FFE3DA !important;
}

.border-right-warning.border-right-accent-4 {
  border-right: 1px solid #FFE3DA !important;
}

.overlay-warning.overlay-accent-4 {
  background: #FFE3DA;
  background: rgba(255, 227, 218, 0.6);
}

.warning.light {
  color: #FFEED9 !important;
}

.bg-warning.bg-light {
  background-color: #FFEED9 !important;
}

.btn-warning.btn-light {
  border-color: #fc960f !important;
  background-color: #FFEED9 !important;
}

.btn-warning.btn-light:hover {
  border-color: #fc960f !important;
  background-color: #ef8903 !important;
}

.btn-warning.btn-light:focus, .btn-warning.btn-light:active {
  border-color: #ef8903 !important;
  background-color: #d67b02 !important;
}

.btn-outline-warning.btn-outline-light {
  border-color: #FFEED9 !important;
  color: #FFEED9 !important;
}

.btn-outline-warning.btn-outline-light:hover {
  background-color: #FFEED9 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEED9 !important;
}

.border-warning.border-light {
  border: 1px solid #FFEED9 !important;
}

.border-top-warning.border-top-light {
  border-top: 1px solid #FFEED9 !important;
}

.border-bottom-warning.border-bottom-light {
  border-bottom: 1px solid #FFEED9 !important;
}

.border-left-warning.border-left-light {
  border-left: 1px solid #FFEED9 !important;
}

.border-right-warning.border-right-light {
  border-right: 1px solid #FFEED9 !important;
}

.overlay-warning.overlay-light {
  background: #FFEED9;
  background: rgba(255, 238, 217, 0.6);
}

.danger.lighten-5 {
  color: #ffdbdb !important;
}

.bg-danger.bg-lighten-5 {
  background-color: #ffdbdb !important;
}

.btn-danger.btn-lighten-5 {
  border-color: #ff2829 !important;
  background-color: #ffdbdb !important;
}

.btn-danger.btn-lighten-5:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-lighten-5:focus, .btn-danger.btn-lighten-5:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-lighten-5 {
  border-color: #ffdbdb !important;
  color: #ffdbdb !important;
}

.btn-outline-danger.btn-outline-lighten-5:hover {
  background-color: #ffdbdb !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffdbdb !important;
}

.border-danger.border-lighten-5 {
  border: 1px solid #ffdbdb !important;
}

.border-top-danger.border-top-lighten-5 {
  border-top: 1px solid #ffdbdb !important;
}

.border-bottom-danger.border-bottom-lighten-5 {
  border-bottom: 1px solid #ffdbdb !important;
}

.border-left-danger.border-left-lighten-5 {
  border-left: 1px solid #ffdbdb !important;
}

.border-right-danger.border-right-lighten-5 {
  border-right: 1px solid #ffdbdb !important;
}

.overlay-danger.overlay-lighten-5 {
  background: #ffdbdb;
  background: rgba(255, 219, 219, 0.6);
}

.danger.lighten-4 {
  color: #ffc1c1 !important;
}

.bg-danger.bg-lighten-4 {
  background-color: #ffc1c1 !important;
}

.btn-danger.btn-lighten-4 {
  border-color: #ff2829 !important;
  background-color: #ffc1c1 !important;
}

.btn-danger.btn-lighten-4:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-lighten-4:focus, .btn-danger.btn-lighten-4:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-lighten-4 {
  border-color: #ffc1c1 !important;
  color: #ffc1c1 !important;
}

.btn-outline-danger.btn-outline-lighten-4:hover {
  background-color: #ffc1c1 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc1c1 !important;
}

.border-danger.border-lighten-4 {
  border: 1px solid #ffc1c1 !important;
}

.border-top-danger.border-top-lighten-4 {
  border-top: 1px solid #ffc1c1 !important;
}

.border-bottom-danger.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffc1c1 !important;
}

.border-left-danger.border-left-lighten-4 {
  border-left: 1px solid #ffc1c1 !important;
}

.border-right-danger.border-right-lighten-4 {
  border-right: 1px solid #ffc1c1 !important;
}

.overlay-danger.overlay-lighten-4 {
  background: #ffc1c1;
  background: rgba(255, 193, 193, 0.6);
}

.danger.lighten-3 {
  color: #ffa8a8 !important;
}

.bg-danger.bg-lighten-3 {
  background-color: #ffa8a8 !important;
}

.btn-danger.btn-lighten-3 {
  border-color: #ff2829 !important;
  background-color: #ffa8a8 !important;
}

.btn-danger.btn-lighten-3:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-lighten-3:focus, .btn-danger.btn-lighten-3:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-lighten-3 {
  border-color: #ffa8a8 !important;
  color: #ffa8a8 !important;
}

.btn-outline-danger.btn-outline-lighten-3:hover {
  background-color: #ffa8a8 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffa8a8 !important;
}

.border-danger.border-lighten-3 {
  border: 1px solid #ffa8a8 !important;
}

.border-top-danger.border-top-lighten-3 {
  border-top: 1px solid #ffa8a8 !important;
}

.border-bottom-danger.border-bottom-lighten-3 {
  border-bottom: 1px solid #ffa8a8 !important;
}

.border-left-danger.border-left-lighten-3 {
  border-left: 1px solid #ffa8a8 !important;
}

.border-right-danger.border-right-lighten-3 {
  border-right: 1px solid #ffa8a8 !important;
}

.overlay-danger.overlay-lighten-3 {
  background: #ffa8a8;
  background: rgba(255, 168, 168, 0.6);
}

.danger.lighten-2 {
  color: #ff8e8f !important;
}

.bg-danger.bg-lighten-2 {
  background-color: #ff8e8f !important;
}

.btn-danger.btn-lighten-2 {
  border-color: #ff2829 !important;
  background-color: #ff8e8f !important;
}

.btn-danger.btn-lighten-2:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-lighten-2:focus, .btn-danger.btn-lighten-2:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-lighten-2 {
  border-color: #ff8e8f !important;
  color: #ff8e8f !important;
}

.btn-outline-danger.btn-outline-lighten-2:hover {
  background-color: #ff8e8f !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8e8f !important;
}

.border-danger.border-lighten-2 {
  border: 1px solid #ff8e8f !important;
}

.border-top-danger.border-top-lighten-2 {
  border-top: 1px solid #ff8e8f !important;
}

.border-bottom-danger.border-bottom-lighten-2 {
  border-bottom: 1px solid #ff8e8f !important;
}

.border-left-danger.border-left-lighten-2 {
  border-left: 1px solid #ff8e8f !important;
}

.border-right-danger.border-right-lighten-2 {
  border-right: 1px solid #ff8e8f !important;
}

.overlay-danger.overlay-lighten-2 {
  background: #ff8e8f;
  background: rgba(255, 142, 143, 0.6);
}

.danger.lighten-1 {
  color: #ff7575 !important;
}

.bg-danger.bg-lighten-1 {
  background-color: #ff7575 !important;
}

.btn-danger.btn-lighten-1 {
  border-color: #ff2829 !important;
  background-color: #ff7575 !important;
}

.btn-danger.btn-lighten-1:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-lighten-1:focus, .btn-danger.btn-lighten-1:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-lighten-1 {
  border-color: #ff7575 !important;
  color: #ff7575 !important;
}

.btn-outline-danger.btn-outline-lighten-1:hover {
  background-color: #ff7575 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff7575 !important;
}

.border-danger.border-lighten-1 {
  border: 1px solid #ff7575 !important;
}

.border-top-danger.border-top-lighten-1 {
  border-top: 1px solid #ff7575 !important;
}

.border-bottom-danger.border-bottom-lighten-1 {
  border-bottom: 1px solid #ff7575 !important;
}

.border-left-danger.border-left-lighten-1 {
  border-left: 1px solid #ff7575 !important;
}

.border-right-danger.border-right-lighten-1 {
  border-right: 1px solid #ff7575 !important;
}

.overlay-danger.overlay-lighten-1 {
  background: #ff7575;
  background: rgba(255, 117, 117, 0.6);
}

/* danger Color Style */
/* ----------------------- */
/* Text color */
/* ---------- */
.danger {
  color: #FF5B5C !important;
}

/* text with light danger */
.text-light-danger {
  color: rgba(255, 91, 92, 0.2) !important;
}

/* Background color */
/* ---------------- */
.bg-danger {
  background-color: #FF5B5C !important;
}

.bg-danger .card-header,
.bg-danger .card-footer {
  background-color: transparent;
}

/* bg color lighten for rgba - opacity set */
.bg-rgba-danger {
  background: rgba(255, 91, 92, 0.2) !important;
}

.bg-rgba-danger.alert {
  color: #FF5B5C;
}

.bg-rgba-danger.alert.alert-dismissible .close {
  color: #FF5B5C;
}

/* Alert danger */
/* ---------------- */
.alert.alert-danger {
  background: #FF5B5C !important;
  color: #fff !important;
  box-shadow: 0 3px 8px 0 rgba(255, 91, 92, 0.4);
  border: none;
}

/* Border danger */
/* ---------------- */
.border-danger {
  border: 1px solid #FF5B5C !important;
}

.border-danger .select2-selection__arrow b {
  border-color: #ff2829 !important;
}

.border-danger.alert {
  color: #FF5B5C;
}

.border-danger.alert.alert-dismissible .close {
  color: #FF5B5C;
}

.border-top-danger {
  border-top: 1px solid #FF5B5C;
}

.border-bottom-danger {
  border-bottom: 1px solid #FF5B5C;
}

.border-left-danger {
  border-left: 1px solid #FF5B5C;
}

.border-right-danger {
  border-right: 1px solid #FF5B5C;
}

/* Navbar icon stroke color according to bg color */
.header-navbar.bg-danger .navbar-nav .nav-item > a i {
  -webkit-text-stroke: 0.2px #FF5B5C !important;
}

/* Badges */
/* ------ */
.badge.badge-danger {
  background-color: #FF5B5C;
}

.badge.badge-light-danger {
  background-color: #FFDEDE;
  color: #FF5B5C !important;
}

.badge.bg-danger.badge-glow, .badge.border-danger.badge-glow, .badge.badge-danger.badge-glow {
  box-shadow: 0px 0px 10px #FF5B5C;
}

.badge-circle-danger {
  background-color: #FF5B5C;
}

.badge-circle-light-danger {
  background-color: #FFDEDE;
  color: #FF5B5C;
}

.overlay-danger {
  background: #FF5B5C;
  background: rgba(255, 91, 92, 0.6);
}

/* Basic buttons */
/* ------------- */
.btn-danger {
  border-color: #ff2829 !important;
  background-color: #FF5B5C !important;
  color: #fff;
}

.btn-danger:hover, .btn-danger.hover {
  background-color: #ff7575 !important;
  color: #fff;
}

.btn-danger:hover.glow, .btn-danger.hover.glow {
  box-shadow: 0 4px 12px 0 rgba(255, 91, 92, 0.6) !important;
}

.btn-danger:focus, .btn-danger:active, .btn-danger.active {
  background-color: #ff4243 !important;
  color: #fff !important;
}

.btn-danger.glow {
  box-shadow: 0 2px 4px 0 rgba(255, 91, 92, 0.5) !important;
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff !important;
}

.btn-light-danger {
  background-color: #FFDEDE;
  color: #FF5B5C !important;
}

.btn-light-danger:hover, .btn-light-danger.hover {
  background-color: #ff7575 !important;
  color: #fff !important;
}

.btn-light-danger:active, .btn-light-danger.active {
  background-color: #ff4243 !important;
  color: #fff !important;
}

/* Outline buttons */
.btn-outline-danger {
  border: 1px solid #FF5B5C;
  background-color: transparent;
  color: #FF5B5C !important;
}

.btn-outline-danger:hover, .btn-outline-danger.hover {
  background-color: #ff7575 !important;
  color: #fff !important;
}

.btn-outline-danger:active, .btn-outline-danger.active {
  background-color: #ff4243 !important;
  color: #fff !important;
}

/* Dropdowns */
/* --------- */
.btn-danger ~ .dropdown-menu .dropdown-item.active, .btn-outline-danger ~ .dropdown-menu .dropdown-item.active {
  background-color: #FF5B5C;
  color: #fff;
}

.btn-danger ~ .dropdown-menu .dropdown-item.active:hover, .btn-outline-danger ~ .dropdown-menu .dropdown-item.active:hover {
  color: #fff;
}

.dropdown.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i, .dropdown.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i,
.dropup.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i,
.dropright.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:hover i,
.dropleft.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:hover i {
  color: #FF5B5C;
}

.dropdown.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i, .dropdown.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i,
.dropup.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i,
.dropright.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-danger ~ .dropdown-menu .dropdown-item:active i,
.dropleft.dropdown-icon-wrapper .btn-outline-danger ~ .dropdown-menu .dropdown-item:active i {
  color: #fff;
}

/* Bullet danger */
.bullet.bullet-danger {
  background-color: #FF5B5C;
}

/* For Pagination */
/* -------------- */
.pagination-danger .page-item.active .page-link, .pagination-danger .page-item.active .page-link:hover {
  background: #FF5B5C !important;
  color: #fff;
}

.pagination-danger .page-item .page-link:hover {
  background-color: #FFDEDE !important;
}

.pagination-danger .page-item.previous .page-link, .pagination-danger .page-item.next .page-link {
  color: #FF5B5C;
}

.pagination-danger .page-item.previous .page-link:hover, .pagination-danger .page-item.next .page-link:hover {
  background: #FF5B5C;
}

/* Progress Bars danger */
/* ---------------------- */
.progress-bar-danger .progress-bar {
  background-color: #FF5B5C;
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}

/* Chips danger */
/* ---------------- */
.chip-danger {
  background-color: #FF5B5C !important;
}

.chip-danger .chip-body {
  color: #fff !important;
}

/* Divider danger */
/* ---------------*/
.divider.divider-danger .divider-text:before, .divider.divider-danger .divider-text:after {
  border-color: #FF5B5C !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-danger:before {
  background: #FF5B5C !important;
}

/* Custom Checkbox & Radio - Colored */
/* --------------------------------- */
input[type="checkbox"].bg-danger + .custom-control-label:before, input[type="radio"].bg-danger + .custom-control-label:before {
  background-color: #FF5B5C !important;
  border-color: #ff2829 !important;
}

.checkbox.checkbox-danger input:checked ~ label::before, .checkbox.radio-danger input:checked ~ label::before, .radio.checkbox-danger input:checked ~ label::before, .radio.radio-danger input:checked ~ label::before {
  background-color: #FF5B5C;
  border-color: #FF5B5C;
}

.checkbox.checkbox-danger input:checked ~ label::after, .checkbox.radio-danger input:checked ~ label::after, .radio.checkbox-danger input:checked ~ label::after, .radio.radio-danger input:checked ~ label::after {
  border-color: #fff;
}

.checkbox.checkbox-danger.checkbox-glow input:checked ~ label::before, .checkbox.checkbox-danger.radio-glow input:checked ~ label::before, .checkbox.radio-danger.checkbox-glow input:checked ~ label::before, .checkbox.radio-danger.radio-glow input:checked ~ label::before, .radio.checkbox-danger.checkbox-glow input:checked ~ label::before, .radio.checkbox-danger.radio-glow input:checked ~ label::before, .radio.radio-danger.checkbox-glow input:checked ~ label::before, .radio.radio-danger.radio-glow input:checked ~ label::before {
  box-shadow: 0 0 6px 0 rgba(255, 91, 92, 0.7);
}

.checkbox.checkbox-danger.checkbox-icon label i, .checkbox.radio-danger.checkbox-icon label i, .radio.checkbox-danger.checkbox-icon label i, .radio.radio-danger.checkbox-icon label i {
  color: #fff;
}

.checkbox.checkbox-danger label:after {
  transition: 250ms ease-in-out;
}

.radio.radio-danger input:checked ~ label::after {
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.35, 0.9, 0.4, 0.9);
}

/* Bootstrap Custom Switches */
/* ------------------------- */
.custom-switch-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FF5B5C !important;
  color: #fff;
  transition: all .2s ease-out;
}

.custom-switch-danger.custom-switch-glow .custom-control-input:not(:disabled):checked ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(255, 91, 92, 0.8) !important;
}

/* Touchspin Glow */
/* -------------- */
.input-group.touchspin-glow .bootstrap-touchspin-down.btn-danger, .input-group.touchspin-glow .bootstrap-touchspin-up.btn-danger {
  box-shadow: 0 0 8px 0 rgba(255, 91, 92, 0.8);
}

/* Scrumboard Application - kanban-item with coloured border */
/* --------------------------------------------------------- */
.kanban-container .kanban-board .kanban-item[data-border=danger]:before {
  background-color: #FF5B5C;
}

.edit-kanban-item select option.bg-color_name {
  background-color: #FF5B5C;
}

/* Select2 danger */
/* ---------------*/
select.select-light-danger ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, select.select-light-danger ~ .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  background-color: #FFDEDE !important;
  color: #FF5B5C !important;
}

.danger.darken-1 {
  color: #ff4243 !important;
}

.bg-danger.bg-darken-1 {
  background-color: #ff4243 !important;
}

.btn-danger.btn-darken-1 {
  border-color: #ff2829 !important;
  background-color: #ff4243 !important;
}

.btn-danger.btn-darken-1:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-darken-1:focus, .btn-danger.btn-darken-1:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-darken-1 {
  border-color: #ff4243 !important;
  color: #ff4243 !important;
}

.btn-outline-danger.btn-outline-darken-1:hover {
  background-color: #ff4243 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff4243 !important;
}

.border-danger.border-darken-1 {
  border: 1px solid #ff4243 !important;
}

.border-top-danger.border-top-darken-1 {
  border-top: 1px solid #ff4243 !important;
}

.border-bottom-danger.border-bottom-darken-1 {
  border-bottom: 1px solid #ff4243 !important;
}

.border-left-danger.border-left-darken-1 {
  border-left: 1px solid #ff4243 !important;
}

.border-right-danger.border-right-darken-1 {
  border-right: 1px solid #ff4243 !important;
}

.overlay-danger.overlay-darken-1 {
  background: #ff4243;
  background: rgba(255, 66, 67, 0.6);
}

.danger.darken-2 {
  color: #ff2829 !important;
}

.bg-danger.bg-darken-2 {
  background-color: #ff2829 !important;
}

.btn-danger.btn-darken-2 {
  border-color: #ff2829 !important;
  background-color: #ff2829 !important;
}

.btn-danger.btn-darken-2:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-darken-2:focus, .btn-danger.btn-darken-2:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-darken-2 {
  border-color: #ff2829 !important;
  color: #ff2829 !important;
}

.btn-outline-danger.btn-outline-darken-2:hover {
  background-color: #ff2829 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff2829 !important;
}

.border-danger.border-darken-2 {
  border: 1px solid #ff2829 !important;
}

.border-top-danger.border-top-darken-2 {
  border-top: 1px solid #ff2829 !important;
}

.border-bottom-danger.border-bottom-darken-2 {
  border-bottom: 1px solid #ff2829 !important;
}

.border-left-danger.border-left-darken-2 {
  border-left: 1px solid #ff2829 !important;
}

.border-right-danger.border-right-darken-2 {
  border-right: 1px solid #ff2829 !important;
}

.overlay-danger.overlay-darken-2 {
  background: #ff2829;
  background: rgba(255, 40, 41, 0.6);
}

.danger.darken-3 {
  color: #ff0f10 !important;
}

.bg-danger.bg-darken-3 {
  background-color: #ff0f10 !important;
}

.btn-danger.btn-darken-3 {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-darken-3:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-darken-3:focus, .btn-danger.btn-darken-3:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-darken-3 {
  border-color: #ff0f10 !important;
  color: #ff0f10 !important;
}

.btn-outline-danger.btn-outline-darken-3:hover {
  background-color: #ff0f10 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff0f10 !important;
}

.border-danger.border-darken-3 {
  border: 1px solid #ff0f10 !important;
}

.border-top-danger.border-top-darken-3 {
  border-top: 1px solid #ff0f10 !important;
}

.border-bottom-danger.border-bottom-darken-3 {
  border-bottom: 1px solid #ff0f10 !important;
}

.border-left-danger.border-left-darken-3 {
  border-left: 1px solid #ff0f10 !important;
}

.border-right-danger.border-right-darken-3 {
  border-right: 1px solid #ff0f10 !important;
}

.overlay-danger.overlay-darken-3 {
  background: #ff0f10;
  background: rgba(255, 15, 16, 0.6);
}

.danger.darken-4 {
  color: #f40001 !important;
}

.bg-danger.bg-darken-4 {
  background-color: #f40001 !important;
}

.btn-danger.btn-darken-4 {
  border-color: #ff2829 !important;
  background-color: #f40001 !important;
}

.btn-danger.btn-darken-4:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-darken-4:focus, .btn-danger.btn-darken-4:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-darken-4 {
  border-color: #f40001 !important;
  color: #f40001 !important;
}

.btn-outline-danger.btn-outline-darken-4:hover {
  background-color: #f40001 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f40001 !important;
}

.border-danger.border-darken-4 {
  border: 1px solid #f40001 !important;
}

.border-top-danger.border-top-darken-4 {
  border-top: 1px solid #f40001 !important;
}

.border-bottom-danger.border-bottom-darken-4 {
  border-bottom: 1px solid #f40001 !important;
}

.border-left-danger.border-left-darken-4 {
  border-left: 1px solid #f40001 !important;
}

.border-right-danger.border-right-darken-4 {
  border-right: 1px solid #f40001 !important;
}

.overlay-danger.overlay-darken-4 {
  background: #f40001;
  background: rgba(244, 0, 1, 0.6);
}

.danger.accent-1 {
  color: #FFEEF1 !important;
}

.bg-danger.bg-accent-1 {
  background-color: #FFEEF1 !important;
}

.btn-danger.btn-accent-1 {
  border-color: #ff2829 !important;
  background-color: #FFEEF1 !important;
}

.btn-danger.btn-accent-1:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-accent-1:focus, .btn-danger.btn-accent-1:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-accent-1 {
  border-color: #FFEEF1 !important;
  color: #FFEEF1 !important;
}

.btn-outline-danger.btn-outline-accent-1:hover {
  background-color: #FFEEF1 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEEF1 !important;
}

.border-danger.border-accent-1 {
  border: 1px solid #FFEEF1 !important;
}

.border-top-danger.border-top-accent-1 {
  border-top: 1px solid #FFEEF1 !important;
}

.border-bottom-danger.border-bottom-accent-1 {
  border-bottom: 1px solid #FFEEF1 !important;
}

.border-left-danger.border-left-accent-1 {
  border-left: 1px solid #FFEEF1 !important;
}

.border-right-danger.border-right-accent-1 {
  border-right: 1px solid #FFEEF1 !important;
}

.overlay-danger.overlay-accent-1 {
  background: #FFEEF1;
  background: rgba(255, 238, 241, 0.6);
}

.danger.accent-2 {
  color: #FFD6DB !important;
}

.bg-danger.bg-accent-2 {
  background-color: #FFD6DB !important;
}

.btn-danger.btn-accent-2 {
  border-color: #ff2829 !important;
  background-color: #FFD6DB !important;
}

.btn-danger.btn-accent-2:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-accent-2:focus, .btn-danger.btn-accent-2:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-accent-2 {
  border-color: #FFD6DB !important;
  color: #FFD6DB !important;
}

.btn-outline-danger.btn-outline-accent-2:hover {
  background-color: #FFD6DB !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD6DB !important;
}

.border-danger.border-accent-2 {
  border: 1px solid #FFD6DB !important;
}

.border-top-danger.border-top-accent-2 {
  border-top: 1px solid #FFD6DB !important;
}

.border-bottom-danger.border-bottom-accent-2 {
  border-bottom: 1px solid #FFD6DB !important;
}

.border-left-danger.border-left-accent-2 {
  border-left: 1px solid #FFD6DB !important;
}

.border-right-danger.border-right-accent-2 {
  border-right: 1px solid #FFD6DB !important;
}

.overlay-danger.overlay-accent-2 {
  background: #FFD6DB;
  background: rgba(255, 214, 219, 0.6);
}

.danger.accent-3 {
  color: #FFECEE !important;
}

.bg-danger.bg-accent-3 {
  background-color: #FFECEE !important;
}

.btn-danger.btn-accent-3 {
  border-color: #ff2829 !important;
  background-color: #FFECEE !important;
}

.btn-danger.btn-accent-3:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-accent-3:focus, .btn-danger.btn-accent-3:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-accent-3 {
  border-color: #FFECEE !important;
  color: #FFECEE !important;
}

.btn-outline-danger.btn-outline-accent-3:hover {
  background-color: #FFECEE !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFECEE !important;
}

.border-danger.border-accent-3 {
  border: 1px solid #FFECEE !important;
}

.border-top-danger.border-top-accent-3 {
  border-top: 1px solid #FFECEE !important;
}

.border-bottom-danger.border-bottom-accent-3 {
  border-bottom: 1px solid #FFECEE !important;
}

.border-left-danger.border-left-accent-3 {
  border-left: 1px solid #FFECEE !important;
}

.border-right-danger.border-right-accent-3 {
  border-right: 1px solid #FFECEE !important;
}

.overlay-danger.overlay-accent-3 {
  background: #FFECEE;
  background: rgba(255, 236, 238, 0.6);
}

.danger.accent-4 {
  color: #FFD3D7 !important;
}

.bg-danger.bg-accent-4 {
  background-color: #FFD3D7 !important;
}

.btn-danger.btn-accent-4 {
  border-color: #ff2829 !important;
  background-color: #FFD3D7 !important;
}

.btn-danger.btn-accent-4:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-accent-4:focus, .btn-danger.btn-accent-4:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-accent-4 {
  border-color: #FFD3D7 !important;
  color: #FFD3D7 !important;
}

.btn-outline-danger.btn-outline-accent-4:hover {
  background-color: #FFD3D7 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD3D7 !important;
}

.border-danger.border-accent-4 {
  border: 1px solid #FFD3D7 !important;
}

.border-top-danger.border-top-accent-4 {
  border-top: 1px solid #FFD3D7 !important;
}

.border-bottom-danger.border-bottom-accent-4 {
  border-bottom: 1px solid #FFD3D7 !important;
}

.border-left-danger.border-left-accent-4 {
  border-left: 1px solid #FFD3D7 !important;
}

.border-right-danger.border-right-accent-4 {
  border-right: 1px solid #FFD3D7 !important;
}

.overlay-danger.overlay-accent-4 {
  background: #FFD3D7;
  background: rgba(255, 211, 215, 0.6);
}

.danger.light {
  color: #FFDEDE !important;
}

.bg-danger.bg-light {
  background-color: #FFDEDE !important;
}

.btn-danger.btn-light {
  border-color: #ff2829 !important;
  background-color: #FFDEDE !important;
}

.btn-danger.btn-light:hover {
  border-color: #ff2829 !important;
  background-color: #ff0f10 !important;
}

.btn-danger.btn-light:focus, .btn-danger.btn-light:active {
  border-color: #ff0f10 !important;
  background-color: #f40001 !important;
}

.btn-outline-danger.btn-outline-light {
  border-color: #FFDEDE !important;
  color: #FFDEDE !important;
}

.btn-outline-danger.btn-outline-light:hover {
  background-color: #FFDEDE !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFDEDE !important;
}

.border-danger.border-light {
  border: 1px solid #FFDEDE !important;
}

.border-top-danger.border-top-light {
  border-top: 1px solid #FFDEDE !important;
}

.border-bottom-danger.border-bottom-light {
  border-bottom: 1px solid #FFDEDE !important;
}

.border-left-danger.border-left-light {
  border-left: 1px solid #FFDEDE !important;
}

.border-right-danger.border-right-light {
  border-right: 1px solid #FFDEDE !important;
}

.overlay-danger.overlay-light {
  background: #FFDEDE;
  background: rgba(255, 222, 222, 0.6);
}
